import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const OverlayPortal = ({id, children}) => {
    const rootElement = document.getElementById((id) ? id : 'root');
    
    if (!rootElement) {
      return null;
    }
  
    return ReactDOM.createPortal(children, rootElement);
  };

const Overlay = ({ onClose, className, id }) => {

  return (
    <OverlayPortal id={id}>
      <button
        className={`${(className) ? className : 'overlay'}`}
        aria-modal="true"
        role="dialog"
        onClick={onClose}
    >
        {/* <div className="overlay-content">
          <button onClick={onClose} aria-label="Close overlay">Close</button>
        </div> */}
      </button>
    </OverlayPortal>
  );
};

export default Overlay;

import { getDictionaryValue } from 'utils/dictionary';
import ErrorUtility from 'utils/errorutility';
import ExhibitorsAPI from '../../../api/exhibitor.js';
import {
    END_LOADING, GET_ALLSPECIALS_PRODUCTS_FAIL, GET_ALLSPECIALS_PRODUCTS_SUCCESS,
    SHOW_LOADING 
} from './actionTypes';

const _exhibitorAPI = new ExhibitorsAPI();

const findSpecialDescriptions = (specials, exhibitorId, lineId) => {
    if (!!specials) {
        let specialsList = [];
        specials.forEach(market => {
            if (!!market.list) {
                market.list.forEach(special => {
                    if (lineId) {
                        if (special.lineId === lineId) specialsList.push(special);
                    } else {
                        specialsList.push(special);
                    }
                });
            }
        });
        return specialsList;
    } else {
        return [];
    }
}

export function fetchSpecials(exhibitorId, lineId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
        });

        return _exhibitorAPI.getExhibitorSpecials(exhibitorId, lineId).then((response) => {
            let specials = findSpecialDescriptions(response, exhibitorId, lineId);
            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_SUCCESS,
                specials: specials,
                specialsCount: (specials) ? specials.length : 0,
                exhibitorId: exhibitorId,
            });
            dispatch({
                type: END_LOADING,
            });

        }).catch(err => {
            ErrorUtility.logError(err, getDictionaryValue('error.specialsData', 'An error ocurred while getting show specials data.'), 'ERROR')
            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_FAIL,
                err: err,
            });
            dispatch({
                type: END_LOADING,
            });
        })
            ;
    };
}
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';


// Platform dependencies
import { createAppState } from 'modules/redux-app-state';

import TypeAheadSearchForm from './TypeAheadSearchForm.jsx';

import { InitialState } from '../reducers/initialState';
import typeaheadsearch from '../reducers/typeAheadSearchReducer';
import * as types from '../actions/actionTypes';

import { transformFieldsToProps } from 'utils/transformfields'
import { resetPagination } from 'utils/resetpagination';
import { withRouter } from "react-router";

import qs from 'query-string';

/**
 * @property appState
 * @description An AppState object to manage the reducer and store state of the application
 */
const appState = createAppState();

/**
 * TypeAheadSearch component class
 * Wrapper for the typeAhead search and results
 * @param {object} props props passing to child
 * @constructor
 */
class TypeAheadSearch extends Component {
    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        const searchResults = Object.assign({}, InitialState.typeaheadsearch, JSON.parse(this.props.results), {
            query: this.props.query,
        });

        this.updateQuery = this.updateQuery.bind(this);

        // Adding search reducers to the store
        appState.reducerRegistry.register({ typeaheadsearch });

        appState.store.dispatch({
            type: types.TYPEAHEADSEARCH_INIT,
            typeaheadsearch: searchResults,
        });

        this.state = {
            query: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q,
        };
    }

    /**
 * Component Update
 */
    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const qsParsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const qsLastParsed = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });
            const lastTerm = qsLastParsed.q || '';
            const term = qsParsed.q || '';
            // let pageNum = 0
            // if(lastTerm !== term){
            //     pageNum = 1
            //     const { match, history } = this.props;
            //     delete qsParsed['page'];
            //     const newHistory = resetPagination(match, this.props.location);
            //     newHistory.search = qs.stringify(qsParsed);
            //     newHistory.pathname = this.props.location.pathname;
            //     console.log(newHistory.pathname);
            //     newHistory.pagenum = pageNum;
            //     history.push(newHistory);
            // }else{
            const pageNum = qsParsed.page || '1';
            //}
            this.setState({
                query: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q,
            });
        }
    }

    /**
     * update search query
     * @param {string} emptyStr passed when func is called
     * @param {string} query updated query
     */
    updateQuery(emptyStr, query) {
        this.setState({
            query,
        });

        // propagate typed input to parent component
        // eslint-disable-next-line no-unused-expressions
        this.props.onChange && this.props.onChange(query);
    }

    /**
     * Renders the JSX view
     * @returns {XML} Rendered component
     */
    render() {
        const { fields, customSubmit, hideSubmitButton, onAfterSubmit, showClearSearch } = this.props;
        if (!fields) {
            if (isExperienceEditorActive()) {
                return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
                <h1 className="alarm">
                    Data is not provided. Contact administrators, please.
                </h1>
            );
        }
        const transformedProps = typeof this.props.fields !== 'undefined' ? transformFieldsToProps(this.props) : this.props;
        return (
            <Provider store={appState.store}>
                <TypeAheadSearchForm
                    {...transformedProps}
                    query={this.state.query}
                    onChange={this.updateQuery}
                    customSubmit = {customSubmit}
                    hideSubmitButton = {hideSubmitButton}
                    onAfterSubmit = {onAfterSubmit}
                    showClearSearch={showClearSearch}
                />
            </Provider>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{query: *}}
 */
TypeAheadSearch.propTypes = {
    query: PropTypes.string, // Search query
    results: PropTypes.string, // Initial results
};

/**
 * @property defaultProps
 * @type {{query: string}}
 */
TypeAheadSearch.defaultProps = {
    query: '',
    results: '{}',
};

export default (
    withRouter(TypeAheadSearch)
);

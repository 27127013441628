import React from 'react';
import Link from '../Link'

interface CampusMenuProps {
  columns: any;
  label?: string;
}

const renderColumn = (column: any, columnIndex: number) => {
    return (
        <div className="top-nav-column" key={`column-${columnIndex}`}>
            {column.groups.map((group: any, index: number) => renderGroup(group, index, columnIndex))}
        </div>
    );
}

const renderGroup = (group: any, groupIndex: number, columnIndex: number) => {
    return (
        <section className="top-nav-group" key={`group-${groupIndex}`}>
            <div className="top-nav-group-heading">{group.heading.data.value}</div>
            <ul
                role="menu"
                aria-label={`Dropdown Menu for ${group.heading.data.value}`}
            >
                {group.items.map((item: any, index: number) => renderItem(item, index, columnIndex))}
            </ul>
        </section>
    );
}

const renderItem = (item: any, index: number, columnIndex: number) => {
    return (
        <li key={index} role="menuitem" className="top-nav-item">
            <Link className="top-nav-item-link" field={item.link.data} href={item.link.data.value.href} title={item.link.data.value.text}>
                <div className={`top-nav-item-heading-wrapper ${columnIndex == 0 ? 'imc-gallery--align-flex-center' : 'imc-gallery--align-flex-start'}`}>
                    <div>
                        <span className="top-nav-item-heading">{item.heading.data.value}</span>
                        <br />
                        <span className="top-nav-item-subheading">{item.subheading.data.value}</span>
                    </div>
                    <div className="top-nav-item-visit">VISIT</div>
                </div>
                {item?.description?.data?.value &&
                <div className="top-nav-item-description">{item.description.data.value}</div>}
            </Link>
        </li>
    );
};

const CampusMenu: React.FC<CampusMenuProps> = ({ columns }) => {
    return (
        <div className="top-nav-dropdown-menu">
            <div className="top-nav-dropdown-grid">
                {columns.map((column: any, index: number) => renderColumn(column, index))}
            </div>
        </div>
    );
};

export default CampusMenu;

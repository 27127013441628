// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import find from 'lodash/find';
import { withRouter } from 'react-router';

//Utils
import { encode, decode } from 'utils/querystring';
import { cleanObjKeys } from 'utils/cleanObjKeys';
import { mobileScreenUtility } from 'utils/mobileScreenUtility';
import { replaceparams } from 'utils/replaceparams';
import { resetPagination } from 'utils/resetpagination';
import { getDictionaryValue } from 'utils/dictionary';

// module dependencies
import FilterItem from './FilterItem';
import Button from '../../../common-components/Button/Button';

/**
 * String representing sortorder
 */
const SORT_ORDER = 'sortorder';

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{location, history, clearAction: *, labelTitle: *, labelClear: *, filters: *, resultTotal: *, fetchData: *,
 * storeFilterAction: *, match, filterKey}}
 */
const propTypes = {
    setSelectedFilterCount: PropTypes.func,
    toggleFiltersOpen: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    clearAction: PropTypes.func,
    labelTitle: PropTypes.string, // Label: Title
    labelClear: PropTypes.string, // Label: Clear filters
    filters: PropTypes.object, // array for creating filter selections
    resultTotal: PropTypes.number, // Number of results
    storeFilterAction: PropTypes.func,
    match: PropTypes.object.isRequired,
    filterKey: PropTypes.string.isRequired,
    isLvdcOnly: PropTypes.bool,
    lvdcExhibitorLabels: PropTypes.object,
    filtersOpen: PropTypes.bool,
    searchLabels: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {
 * {labelTitle: string, labelClear: string, resultTotal: number, filters: null, clearAction: null,
 * storeFilterAction: null, match: {path: string, params: {}}, fetchData: function()}
 * }
 */
const defaultProps = {
    setSelectedFilterCount: null,
    toggleFiltersOpen: null,
    filtersOpen: false,
    labelTitle: 'Category Filters',
    labelClear: 'Clear All Filters',
    resultTotal: 0,
    filters: null,
    clearAction: null,
    storeFilterAction: null,
    match: {
        path: '',
        params: {},
    },
    isLvdcOnly: false,
    lvdcExhibitorLabels: {
        offeringFilterKey: 'specialServiceOfferings',
        lvdcTag: 'LVDC',
    },
    searchLabels: {},
};

/**
 * clear pagenum from the basepath
 * and replace url params with values
 * @param {object} match url match object
 * @returns {string} path with filled in param values
 */
function cleanedBasepath(match) {
    const updatedParams = {
        ...match.params,
        pagenum: '',
    };
    return replaceparams(match.url, updatedParams);
}

/**
 * Component for displaying a Filter module and maintaining its state
 */
class Filter extends Component {
    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        const cachedWidth = 800;

        this.state = {
            selectedFilters: {},
            selectedMobileFilters: {},
            selectedMobilePastFilters: {},
            mobileExpanded: false,
            filtersCount: 0,
        };
        this.filterGlobal = '';
        this.filterUpdated = this.filterUpdated.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.parseFilters = this.parseFilters.bind(this);
        this.populateFilters = this.populateFilters.bind(this);
        this.toggleMobileContent = this.toggleMobileContent.bind(this);
        this.hideMobileFilter = this.hideMobileFilter.bind(this);
        this.clearFilterSelection = this.clearFilterSelection.bind(this);
        this.sendFilterCountUpdate = this.sendFilterCountUpdate.bind(this);
        this.onResize = this.onResize.bind(this, cachedWidth);
    }

    /**
     * Populates the filters based on the current querystring
     */
    componentDidMount() {
        this.populateFilters();
        window.addEventListener('resize', this.onResize);

        const { location, filterKey } = this.props;
        const oldQueryString = Object.assign({}, decode(location.search));
        const queryString = Object.assign({}, decode(location.search.replace('%7C', '|')));
        const thisTabQuery = queryString[filterKey] &&
            queryString[filterKey].length > 0 ? decode(queryString[filterKey][0]) : {};
        this.sendFilterCountUpdate(thisTabQuery);
        if (queryString[filterKey] && (queryString[filterKey] != encode(thisTabQuery, false) || oldQueryString[filterKey] != encode(thisTabQuery, false))) {
            queryString[filterKey] = encode(thisTabQuery, false);
            this.updateURL(queryString, false);
        }
    }

    /**
     * If the new search query isn't a match, update the selected filters
     * @param {object} lastProps the next properties incoming from parent.
     */
    componentDidUpdate(lastProps) {
        if ((lastProps.filters !== null && this.props.filters === null && lastProps.filters !== this.props.filters) || lastProps.location !== this.props.location) {
            
            this.parseFilters(this.props.filters, this.props.location);

            this.populateFilters(this.props.location);
            if (this.props.filtersOpen) {
                this.setState({
                    mobileExpanded: true,
                });
            }
        }
        if (this.props.location.search !== lastProps.location.search) {
            const { location, filterKey } = this.props;
            console.log('Decoding', location.search, decode(location.search));
            const queryString = decode(location.search);
            if (lastProps.location.search !== this.props.location.search && queryString[filterKey] && queryString[filterKey].length > 0) {
                let thisTabQuery = decode(queryString[filterKey][0]);
                if (queryString[filterKey] && queryString[filterKey] != encode(thisTabQuery, false)) {
                    queryString[filterKey] = encode(thisTabQuery, false);
                    this.updateURL(queryString, false);
                }
            }
        }
    }
    /**
     * Remove event Listeners on window
     */
    componentWillUnmount() {
        const { selectedFilters } = this.state;
        window.removeEventListener('resize', this.onResize);

        // clear any selected filters from the url
        if (Object.keys(selectedFilters).length > 0) {
            // clear filter selections
            // and mark search results as out of date,
            // but dont immediately conduct a new search
            // this.clearFilterSelection(false);
        }
    }

    /**
     * On Resize function
     * @param {number} cachedWidth cachedWidth
     */
    onResize(cachedWidth) {
        const newWidth = (typeof window !== "undefined") ? window.innerWidth : 800;
        if (newWidth !== cachedWidth) {
            let resizeTimer;
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                const isMobile = mobileScreenUtility();
                if (!isMobile) {
                    this.setState({
                        mobileExpanded: false,
                    });
                }
            }, 500);
        }
    }

    /**
     * Populates the filters based on the current querystring
     */
    populateFilters(location = this.props.location) {
        const { filterKey } = this.props;
        const selectedFilters = decode(location.search);
        let thisTabFilters = {};
        if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
            if (typeof selectedFilters[filterKey][0] === 'string') {
                thisTabFilters = decode(selectedFilters[0]);
            } else {
                thisTabFilters = selectedFilters[filterKey][0];
            }
        }

        if (thisTabFilters.q) {
            delete selectedFilters.q;
            delete selectedFilters.filter;
        }
        this.setState({
            selectedFilters,
        });
    }

    /**
     * Determines if extra filters have been passed on page load and will parse out the filters that aren't needed
     * @param {object} filters Next incoming filter object
     */
    parseFilters(filters, location) {
        const { filterKey } = this.props;
        const selectedFilters = decode(location.search);
        let filtersModified = false;
        const urlFilters = selectedFilters[filterKey] &&
            selectedFilters[filterKey].length > 0 ? decode(selectedFilters[filterKey][0]) : {};
        Object.keys(urlFilters).forEach((key) => {
            if (key !== 'filter') {
                if (filters[key]) {
                    let checkFilterArray = [...filters[key].subFilters];
                    filters[key].subFilters.forEach((item) => {
                        if (item.subFilters) {
                            checkFilterArray = checkFilterArray.concat(item.subFilters);
                        }
                    });
                    const newArray = [];
                    Object.keys(urlFilters[key]).forEach((childKey) => {
                        if (find(checkFilterArray, {
                            name: urlFilters[key][childKey],
                        })) {
                            newArray.push(urlFilters[key][childKey]);
                        } else {
                            filtersModified = true;
                        }
                    });
                    if (newArray.length == 0)
                        delete urlFilters[key];
                    else
                        urlFilters[key] = newArray;
                }
            }
        });
        if (filtersModified) {
            selectedFilters[filterKey] = [encode(urlFilters, false, false)];
            this.updateURL(selectedFilters, true);
            this.setState({
                selectedFilters,
            });
        }
    }


    /**
     * Passes out the updated filter whenever there is a change
     * @param {object} filter Updated filter to construct
     * * @param {object} filterForAnalytics filter sent to analitycs. It sends only the parent if all children were selected
     */
    filterUpdated(filter) {

        this.filterGlobal = filter;
        const { location, filterKey, isLvdcOnly, lvdcExhibitorLabels, filters } = this.props;
        const { selectedFilters } = this.state;
        const { offeringFilterKey, lvdcTag } = lvdcExhibitorLabels;
        const currentQuery = Object.assign({}, decode(location.search));
        let filterObjectKeys = [];

        // Killing filter here to have a clean check on the number of filters.
        const queryString = Object.assign({}, decode(location.search));
        delete currentQuery[filterKey];
        let thisTabQuery = queryString[filterKey] &&
            queryString[filterKey].length > 0 ? decode(queryString[filterKey][0]) : {};
        let newFilters = Object.assign({}, thisTabQuery, filter);

        if (isLvdcOnly) {
            if (newFilters[offeringFilterKey]) {
                if (newFilters[offeringFilterKey].indexOf(lvdcTag) === -1) {
                    newFilters[offeringFilterKey].push(lvdcTag);
                }
            } else {
                newFilters[offeringFilterKey] = [lvdcTag];
            }
        }

        if (newFilters.filter && newFilters.filter.length > 0) {
            newFilters.filter = null;
        }
        // Trim the fat from the object
        // newFilters = cleanObjKeys(newFilters);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length === 1 && filterObjectKeys.indexOf(SORT_ORDER) === 0) {
            newFilters.filter = false;
            thisTabQuery = newFilters;
            queryString[filterKey] = encode(thisTabQuery, false);
        }
        newFilters = cleanObjKeys(newFilters);
        thisTabQuery = newFilters;
        this.sendFilterCountUpdate(thisTabQuery);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length !== 0) {
            // newFilters.filter = true;
            queryString[filterKey] = encode(thisTabQuery, false);
        } else {
            delete queryString[filterKey];
        }

        this.updateURL(queryString, true);
        this.setState({
            selectedFilters: cleanObjKeys(thisTabQuery),
            selectedMobileFilters: selectedFilters,
            selectedMobilePastFilters: cleanObjKeys(selectedFilters),
        });
    }

    /**
     * Send the filter count up to the HOC.
     * @param {object} thisTabQuery The query object for which app we are in.
     */
    sendFilterCountUpdate(thisTabQuery) {
        let filtersCount = 0;
        Object.keys(thisTabQuery).forEach((key) => {
            if (key !== 'filter') {
                filtersCount += thisTabQuery[key].length;

                let hasParents = false;
                let parentCount = 0;
                Object.keys(thisTabQuery[key]).forEach((childKey) => {
                    if (thisTabQuery[key][childKey].indexOf('.') > -1) {
                        hasParents = true;
                    } else {
                        parentCount++;
                    }
                });
                if (hasParents) {
                    filtersCount -= parentCount;
                }
            }
        });
        this.setState({
            filtersCount: filtersCount,
        });
    }

    /**
     * Pushed updated search query to history
     * @param {object} searchQuery object containing selected filters and search term
     * @param {bool} resetPage Determines if pagination should be set back to 1
     */
    updateURL(searchQuery, resetPage = false) {
        const { history, match, location } = this.props;
        const newHistory = resetPagination(match, location);
        let basepath;
        if (resetPage) {
            delete searchQuery['page'];
            basepath = cleanedBasepath(match);
            newHistory.search = (searchQuery);
            history.replace(`${basepath}?${encode(searchQuery ,false, false)}`, newHistory);
        } else {
            newHistory.search = encode(searchQuery, false, false);
            history.replace(newHistory);
        }
    }

    /**
     * select clear function Btn based on Mobile Filter function
     * @param {object} e Event action from click event
     * @param {bool} runSearch should new data be fetched based on cleared filter selections?
     */
    clearFilterSelection(e, runSearch = true) {
        e.preventDefault();
        this.clearFilter(runSearch);
    }

    /**
     * Clears the filter of all selected options
     * @param {bool} runSearch should new data be fetched based on cleaned filter selections?
     */
    clearFilter(runSearch = true) {
        const { clearAction, location, filterKey } = this.props;
        const queryObj = qs.parse(location.search);
        if (filterKey) {
            delete queryObj[filterKey];
        }
        this.updateURL(queryObj, true);
        // in some cases, we don't want to trigger
        // a seach immediately, but need to mark the current
        // search results as out of date in relation to selected filters.
        // eg, on componentWillUnmount, clearing filters but not wanting to
        // immediately execute a new search.

        const cleanedFilters = {
            selectedFilters: {},
        };

        const updatedState = mobileScreenUtility() ?
            {
                ...cleanedFilters,
                selectedMobileFilters: {},
            } : cleanedFilters;

        if (clearAction && runSearch) {
            clearAction();
        }
        updatedState.filtersCount = 0;
        this.setState(updatedState);

        if (this.props.setSelectedFilterCount) {
            this.props.setSelectedFilterCount(this.props.filterKey, 0);
        }
    }

    /**
     * Toggle Content on Mobile
     */
    toggleMobileContent() {
        this.setState({
            mobileExpanded: !this.state.mobileExpanded,
        });
    }
    /**
     * Hide Mobile Filter
     */
    hideMobileFilter() {
        this.toggleMobileContent();
    }

    /**
     * Renders the filter items
     * @returns {(Array|null)} Array of JSX filter items or nothing if no filters are available
     */
    renderFilterControlsMobile() {
        return (
            <div className="imc-filter__mobileheader">
                <div className="imc-filter__mobileheader__closewrapper">
                    <button
                        className="imc-filter__mobileheader__closewrapper__button"
                        onClick={this.hideMobileFilter}
                    >
                        {getDictionaryValue('close', 'Close')}
                    </button>
                </div>
            </div>
        );
    }
    /**
     * Renders the filter items
     * @returns {(Array|null)} Array of JSX filter items or nothing if no filters are available
     */
    renderFilters() {
        const { filters, filterKey, searchLabels } = this.props;
        const { selectedFilters } = this.state;
        const filterItems = [];
        let thisTabFilters = {};
        if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
            thisTabFilters = decode(selectedFilters[filterKey][0]);
        }
        if (filters) {
            Object.keys(filters).forEach((item) => {
                const filterOfInterest = filters[item];
                filterItems.push(
                    <FilterItem
                        filterCategory={item}
                        filterKey={filterKey}
                        key={`filterItem-${filters[item].name}`}
                        {...filterOfInterest}
                        updateFilter={(filter) => this.filterUpdated(filter)}
                        selectedFilters={thisTabFilters[item]}
                        searchLabels={searchLabels}
                    />,
                );
            });
        }

        return filterItems;
    }

    /**
     * Renders the clear filter link if filters are currently selected
     * @returns {*} Clear Filter Link (if applicable)
     */
    renderClearFilterLink() {
        const { labelClear, filterKey } = this.props;
        const { selectedFilters, mobileExpanded } = this.state;
        const clearAllClass = mobileExpanded ? '' : 'imc-filter__filters-mobile__clearall';
        let isDisabled = false;
        if (!selectedFilters || Object.keys(selectedFilters).indexOf(filterKey) === -1) {
            isDisabled = true;
        }
        return (
            <button
                disabled={isDisabled}
                tabIndex={0}
                onClick={this.clearFilterSelection}
                className={`imc-link imc-content--delta imc-filter__clearall imc-filteritem__clearlink
                ${clearAllClass}`}
                data-xpath="filter.clearlink"
            >
                {labelClear}
            </button>
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { labelTitle, location } = this.props;
        const { mobileExpanded, selectedFilters, filtersCount } = this.state;
        const mobileClasses = mobileExpanded ?
            'imc-expand-collapse--expanded' :
            'imc-expand-collapse';
        const filterWrapperClass = mobileExpanded ? 'imc-filter__filters-mobile-item-wrapper' : '';
        if (typeof document !== "undefined")
            if (mobileExpanded) {
                window.scrollTo(0, 0);
                document.body.classList.add('imc-filter__mobile-filter-body-no-scroll');
            } else {
                document.body.classList.remove('imc-filter__mobile-filter-body-no-scroll');
            }
        return (
            <div
                className={`imc-filter imc-content ${mobileClasses}`}
                data-xpath="filter.container"
            >
                <div className="imc-filter__filter-header">
                    <button
                        className="imc-filter__header imc-breakpoint-display--mobile-only imc-type--title-6
                        imc-exhibitors-heading"
                        onClick={this.toggleMobileContent}
                    >
                        <div className="imc-content--display-flex imc-content--display-flex-center">
                            {getDictionaryValue('categoryFilters', labelTitle)}
                        </div>
                        {filtersCount > 0 ?
                            <span className="imc-filter__header_count">{`${filtersCount} ${getDictionaryValue('filtersSelected', 'Filters Selected')}`} </span> :
                            <span className="imc-filter__header_nocount">{getDictionaryValue('noFiltersSelected', 'No Filters Selected')} </span>
                        }
                    </button>
                </div>
                <div className={`imc-filter__filters imc-expand-collapse__content ${filterWrapperClass}`}>
                    {this.renderFilterControlsMobile()}
                    <div className='imc-filter--reset-button-container'>
                        <p className='imc-filter--title'>Filters</p>
                        <Button
                            displayName={"Reset"}
                            cssClass="imc-filter--reset-button"
                            onClick={this.clearFilterSelection}
                        />
                    </div>
                    <div className="imc-filter__filters-mobile-filter-wrapper">
                        {this.renderFilters()}
                    </div>
                </div>
            </div>
        );
    }
}

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export { Filter as UnwrappedFilter };
export default withRouter(Filter);

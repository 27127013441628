import React, { useRef, useState, useEffect } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { richTextRendering } from 'utils/buildRenderingParameters';
import RichTextComponent from '../Rich-Text-Component';
import { padContent, unPadContent } from 'utils/scrollhelpers';

// package does not have typescipt definitions
const HubspotForm: any = require('react-hubspot-form');

interface HubSpotFormProps {
    fields?: any;
}

function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
}

const HubSpotForm: React.FC<HubSpotFormProps> = (props) => {

    const formContainerRef = useRef<HTMLDivElement>(null);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = useState<string>('');
    const [height, setHeight] = useState('auto');
    
    const handleSubmit = (event: Event) => {

        if (formContainerRef.current?.querySelector('form')) {
            const form = formContainerRef.current.querySelector('form');
            if (form) {
                // @ts-ignore
                // Check for fields with class 'invalid' or 'error'
                const invalidFields = form.querySelectorAll('.invalid, .error');

                // Check for required fields that are empty
                const requiredFields = form.querySelectorAll('[required]');
                let hasEmptyRequiredFields = false;
                requiredFields.forEach((field) => {
                    if (!(field as HTMLInputElement).value) {
                        hasEmptyRequiredFields = true;
                    }
                });

                if (invalidFields.length > 0 || hasEmptyRequiredFields) {
                    // If there are invalid fields, prevent form submission
                    return;
                } else {
                    if (props?.fields?.redirectUrl) { 
                        event.preventDefault(); // Prevent the default form submission behavior

                        // Prepare form data
                        const formData = new FormData(form);
                        
                        // Get hs_context value from the hidden input field
                        const hsContextInput = form.querySelector<HTMLInputElement>('input[name="hs_context"]');
                        if (hsContextInput) {
                            formData.append('hs_context', hsContextInput.value);
                        }

                        // Convert form data to URLSearchParams
                        const urlParams = new URLSearchParams();

                        // Convert form data to JSON
                        const jsonData: { [key: string]: any } = {
                            "fields": [],
                            "context": {
                                "hutk": getCookie('hubspotutk'),
                                "pageUri": window.location.href,
                                "pageName": document.title,
                            }
                        };
                        
                        // if (hsContextInput?.value) {
                        //     jsonData.context.hs_context = hsContextInput.value;
                        // }
                        
                        formData.forEach((value, key) => {
                            if (key !== 'hs_context') {
                                jsonData.fields.push({
                                    "name": key,
                                    "value": value
                                });
                            }
                            // Add key-value pairs to URLSearchParams
                            urlParams.append(key, value.toString());
                        });
                                        
                        // Send API request to HubSpot
                        const response = fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${props.fields.accountID.value}/${props.fields.formID.value}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(jsonData)
                        }).then(response => response.json()).then((responseData : any) => {
                            if (formContainerRef?.current) setHeight(formContainerRef.current.offsetHeight + 'px');
                            setIsSubmitted(true);
                            const hasQueryParams = responseData.redirectUri.includes('?');
                            const separator = hasQueryParams ? '&' : '?';
                            if (props.fields.openModal?.value) {
                                padContent(window.innerWidth - document.documentElement.getBoundingClientRect().width, ['.overlay-x']);
                                setIframeSrc(`${responseData.redirectUri}${separator}${urlParams.toString()}`);
                                setIsOpen(true);
                            } else {
                                window.open(`${responseData.redirectUri}${separator}${urlParams.toString()}`, '_blank');
                            }
                        });
                    }
                }
            }
        }
    };

    useEffect(() => {}, [isSubmitted]);

    useEffect(() => {
        const formContainer = formContainerRef.current;
        const form = formContainer?.querySelector('form');

        if (form) {
            const submitButton = form.querySelector<HTMLInputElement>('input[type="submit"]');
            if (submitButton) {
                submitButton.addEventListener('click', handleSubmit);
            }
            form.addEventListener('keypress', (event) => {
                if (event.key === 'Enter') {
                    handleSubmit(event);
                }
            });
        }

        // Cleanup event listener on unmount
        return () => {
            if (form) {
                const submitButton = form.querySelector<HTMLInputElement>('input[type="submit"]');
                if (submitButton) {
                    submitButton.removeEventListener('click', handleSubmit);
                }
                form.removeEventListener('keypress', (event) => {
                    if (event.key === 'Enter') {
                        handleSubmit(event);
                    }
                });
            }
        };
    }, [isFormLoaded]);

    let submittedMessage = richTextRendering();
    submittedMessage.fields.content.value = props.fields.thankYouMessage.value;

    return (
        <>
            <div className="imc-formfield hubspot-form" ref={formContainerRef} style={{ height }}>
                <span style={{ pointerEvents: isExperienceEditorActive() ? 'none' : 'all' }}>
                    {(!isSubmitted) &&
                        <HubspotForm
                            portalId={props.fields.accountID.value}
                            formId={props.fields.formID.value}
                            loading={<div><strong>Loading Form</strong><br /><svg width="200" height="200"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading" /></svg><span className="imc-content--display-none amc-www amc-www-check"></span></div>}
                            cssRequired=''
                            cssClass='imc-formfield'
                            submitButtonClass={`imc-button imc-button--large ${props.fields.submitButtonClass.value}`}
                            inlineMessage={(props.fields.thankYouMessage.value) ? props.fields.thankYouMessage.value : ""}
                            autofill={true}
                            onFormReady={(form: any) => {
                                setIsFormLoaded(true);
                            }}
                        />
                    }
                    {(isSubmitted) && (props.fields.thankYouMessage.value) ? <RichTextComponent {...submittedMessage} rendering={submittedMessage} /> : <></>}

                </span>
            </div>
            {(props?.fields?.redirectUrl?.value) && (props?.fields?.openModal?.value) &&
                <div
                    style={{
                        '--overlay-color': (props.fields?.overlayColor?.value) ? `var(--${props.fields.overlayColor.value})` : '',
                        '--overlay-opacity': (props.fields?.overlayOpacity?.value) ? (parseInt(props.fields.overlayOpacity.value) / 100) : ''
                    } as React.CSSProperties}
                    className={`after-overlay top-nav-overlay imc-modal--overlay ${(props.fields?.hasPadding?.value == true) ? `has-padding` : ``} ${isOpen ? 'active' : ''}`}
                    onClick={() => {
                        setIsOpen(false);
                        unPadContent(['.overlay-x']);
                    }}
                >
                    <span onClick={() => {
                        setIsOpen(false);
                        unPadContent(['.overlay-x']);
                    }} dangerouslySetInnerHTML={{__html: `&times;`}} className={`overlay-x ${(props.fields.xColor.value) ? `imc-type--color-${props.fields.xColor.value}` : ``}`}></span>
                    <div>
                        <iframe src={iframeSrc}></iframe>
                    </div>
                </div>

            }
        </>
    );
}

export default HubSpotForm;

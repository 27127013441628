import React, { useState, useEffect, useLayoutEffect, useRef, RefObject, useCallback } from "react";
import { withRouter, RouteComponentProps, useLocation } from 'react-router';
import Link from '../Link';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';
import { MobileNavigationMenu } from "./interface";
import PortalComponent from "modules/portal";
import SvgIcon from 'modules/svgicon';
import { set } from "lodash";

interface TopHeaderComponentProps extends RouteComponentProps<any> {
    rendering: any;
    navMenu: MobileNavigationMenu;
}

const DesktopNavMenu: React.FC<TopHeaderComponentProps> = ({rendering, navMenu, location}) => {

    const [activeMenu, setActiveMenu] = useState<any>(null);
    const [lastActiveMenu, setLastActiveMenu] = useState<any>(null);
    const [menuHeight, setMenuHeight] = useState<number>(0);
    const [showMenuClose, setShowMenuClose] = useState<boolean>(false);
    const isLoggedIn = ((Environment.isHot != undefined) || UserUtility.isLoggedIn());
    const firstItem = useRef<HTMLElement>(null);
    const lastItem = useRef<HTMLElement>();
    const exitItem = useRef<HTMLButtonElement>(null);
    const firstSubItem = useRef<HTMLButtonElement>(null);
    const lastSubItem = useRef<HTMLElement>();
    const menuRef = useRef<HTMLDivElement>(null);
    const collapseWrapperRef = useRef<HTMLDivElement>(null);
    const user = isLoggedIn ? UserUtility.getUserData() : {
        firstName: "George",
        lastName: "Burdell"
    };
    const name = (user && user.firstName && user.lastName) ? `${user.firstName} ${user.lastName.charAt(0)}.` : `George B.`;


    let desktopNav = null;
    if (rendering && rendering.placeholders && rendering.placeholders['jss-top-header-bottom']) {
        desktopNav = rendering.placeholders['jss-top-header-bottom'].filter(function(item?: any) {
            return item.componentName === 'Header-Navigation-Component';
        })[0].fields.data.model.navigation1st;
    }

    useEffect(() => {
        setShowMenuClose(false);
        sizeMenu(activeMenu, lastActiveMenu);
        setTimeout(() => {
            if (activeMenu !== null && firstItem.current) firstItem.current.focus();
        }, 250);
    }, [activeMenu, lastActiveMenu])

    useEffect(() => {
        setActiveMenu(null);
    }, [location]);

    useLayoutEffect(() => {

        const handleKeyDown = (event: KeyboardEvent) => {

            const lastSubElement = document.querySelector('.last-sub-item') as HTMLElement;
            const currentActiveElement = document.querySelector(`.mobile-menu-${activeMenu}`) as HTMLElement;

            if (event.key === 'Tab') {
                if (event.shiftKey) {
                    if (exitItem?.current && firstItem?.current && document.activeElement == firstItem.current) {
                        event.preventDefault();
                        exitItem.current.focus();
                    }
                } else {
                    if (exitItem?.current && firstItem?.current && document.activeElement == exitItem.current) {
                        event.preventDefault();
                        firstItem.current.focus();
                    }
                }
            } else if (event.key === 'Escape') {
                if (activeMenu !== null) {
                    currentActiveElement.focus();
                    setActiveMenu(null);
                    setLastActiveMenu(activeMenu);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [activeMenu]);  

    const handleClick = (event: any, status: boolean, menu: number|null) => {
        // if (menu === null) {
        //     const lastElement = document.querySelector(`.mobile-menu-${activeMenu}`) as HTMLElement;
        //     lastElement?.focus();
        // }
        if (status) {
            if (event) setActiveMenu(menu);
            if (event && menu !== null) setLastActiveMenu(menu);
        } else {
            if (event) setActiveMenu(null);
            if (event) setLastActiveMenu(menu);
        }
    }

    const handleScroll = (event: any) => {
        setActiveMenu(null);
    }

    const sizeMenu = (activeMenu: number, lastActiveMenu: number) => {
        if (menuRef.current) {
            const children = Array.from(menuRef.current.children);
            let current = null;
            const heights = children.map((child, index) => {
                if (activeMenu === index) current = (child as HTMLElement).offsetHeight;
                return (child as HTMLElement).offsetHeight;
            }, 0);
            if (current) {
                setMenuHeight((current) ? current : Math.max(...heights));
            } else {
                setMenuHeight(0);
            }
        }
    }

    return (
        <>
        <div ref={collapseWrapperRef} className={`imc-content--display-flex imc-content--display-flex-gap-small imc-content--display-flex-justify-center imc-content--full-height`}>
            {desktopNav.map((menu: any, f: number) => {
                return (
                    <>
                    <DropdownMenu
                        wrapperClass={`${(activeMenu !== null && f !== activeMenu) ? 'inactive' : 'active'}`}
                        buttonClassName={`imc-navigation--block mobile-menu-${f} imc-button imc-section--padded-xlarge imc-section--padded-left-xlarge imc-section--padded-right-xlarge imc-content--gamma imc-content--bold imc-content--height-fit-content imc-content--min-height-100`}
                        key={`top-nav-plan-${f}`}
                        label={menu.title.data.value}
                        chevron={false}
                        isCollapsible={true}
                        collapseRef={collapseWrapperRef}
                        preventFocus={true}
                        menuRef={menuRef}
                        clickHandler={(event: any, status: boolean) => {
                            handleClick(event, status, f)
                        }}
                        escapeHandler={(event: any, status: boolean, forceClear?: boolean) => {
                            const clickIsOutside = !((collapseWrapperRef?.current && collapseWrapperRef?.current.contains(event?.target)) ||  (menuRef?.current && menuRef?.current.contains(event?.target)));
                            if (event && (forceClear || activeMenu === f) && clickIsOutside) {
                                handleClick(event, status, null);
                            }
                        }}
                        openState={activeMenu === f}
                    ><></></DropdownMenu>
                    </>
                );
            })}
        </div>
        <PortalComponent targetId={'desktop-menu'}>
            <div className={`imc-navigation--wrapper ${activeMenu !== null ? 'active' : ''}`}>
                <div className={`imc-content--relative imc-content--drop-shadow-light`}>
                    <div ref={menuRef} style={{height: `${menuHeight}px`}}>
                        {desktopNav.map((menu: any, f: number) => {
                            return (
                                <div className={`imc-content--top-0 imc-content--absolute imc-navigation--wrapper-menu${(f === activeMenu) ? ' active' : ''}`} tabIndex={(f === activeMenu) ? 0 : -1} aria-hidden={(f === activeMenu) ? false : true}>
                                    <div className={`imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--display-flex-space-evenly imc-section--padded-left-xlarge imc-section--padded-right-xlarge imc-section--padded-xlarge`}>
                                        {menu.navigationColumn.map((column: any, c: number) => {
                                            return (
                                                <div className={`imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-1 imc-content--display-flex-gap-medium imc-section--padded-micro`}>
                                                    {column.navigation2st.map((item: any, i: number) => {
                                                        const isFirstItem = f === activeMenu && c === 0 && i === 0;
                                                        const linkRef = isFirstItem ? firstItem : null;
                                                        return (
                                                            <>
                                                                {(item.link.data.value.href !== 'http://' && item.link.data.value.href !== '') && (item.link.data.value.text !== '') && <Link
                                                                    className={`imc-content--gamma imc-content--bold imc-link--hover-underline`}
                                                                    field={item.link.data}
                                                                    key={`top-nav-plan-${f}-col-${c}-item-${i}`}
                                                                    onClick={(event: any, status: boolean) => {
                                                                        handleClick(event, false, null)
                                                                    }}
                                                                    ref={linkRef}
                                                                    tabIndex={(f === activeMenu) ? 0 : -1}
                                                                />}
                                                                {(item.link.data.value.href == 'http://') && (item.link.data.value.text !== '') && <div
                                                                    className={`imc-content--gamma imc-content--bold`}
                                                                    key={`top-nav-plan-${f}-col-${c}-item-${i}`}
                                                                ><span>
                                                                    {item.link.data.value.text}
                                                                </span>
                                                                </div>}
                                                                {(item.link.data.value.href == '') && <div
                                                                    className={`imc-content--gamma imc-content--bold imc-content--pointer-events-none`}
                                                                    key={`top-nav-plan-${f}-col-${c}-item-${i}`}
                                                                ><span>
                                                                    &nbsp;
                                                                </span>
                                                                </div>}
                                                                {item.navigation3st.map((subitem: any, j: number) => {
                                                                    return (
                                                                        <Link
                                                                            className={`imc-content--gamma imc-link--hover-underline`}
                                                                            field={subitem.link.data}
                                                                            key={`top-nav-plan-${f}-col-${c}-item-${i}-subitem-${j}`}
                                                                            onClick={(event: any, status: boolean) => {
                                                                                handleClick(event, false, null)
                                                                            }}
                                                                            tabindex={(f === activeMenu) ? 0 : -1}
                                                                        />
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {(f === activeMenu) && <div className={`imc-content--absolute imc-content--top-right ${(showMenuClose) ? '' : 'imc-content--height-none imc-content--overflow-hidden'}`}>
                                        <div className={``}>
                                            <button
                                                className={`imc-button imc-button--search imc-button--transparent-mobile-header imc-button--transparent juniper-search-v3  active`}
                                                onFocus={() => {
                                                    setShowMenuClose(true);
                                                }}
                                                onBlur={() => {
                                                    setShowMenuClose(false);
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    const currentActiveElement = document.querySelector(`.mobile-menu-${activeMenu}`) as HTMLElement;
                                                    setActiveMenu(null);
                                                    setShowMenuClose(false);
                                                    setTimeout(() => {
                                                        currentActiveElement.focus();
                                                    }, 250);
                                                }}
                                                ref={exitItem}
                                            >
                                                <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-orange-hover`} />
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </PortalComponent>
        </>
    )
}

export default withRouter(DesktopNavMenu);
import * as types from '../actions/actionTypes';
import { InitialState } from './initialState';

/**
 * Reducer for search actions
 * @param {object} state State of the search
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function typeAheadSearchReducer(state = InitialState.typeaheadsearch, action) {
    switch (action.type) {
    case types.TYPEAHEADSEARCH_INIT:
        return Object.assign({}, state, action.typeaheadsearch);

    case types.TYPEAHEADSEARCH_SUCCESS:
        return Object.assign({}, state, action.typeaheadsearch, action.query);

    case types.TYPEAHEADSEARCH_FAIL:
        return Object.assign({}, InitialState.typeaheadsearch);

    case types.TYPEAHEADSEARCH_UPDATE_CURRENT_QUERY:
        return Object.assign({}, state, action.query);

    case types.TYPEAHEADSEARCH_RESET:
        return Object.assign({}, state, { options: [] });

    default:
        return state;
    }
}

const sectionJSON = require('../../components/Content-Section-Component/props.json');
const richTextJSON = require('../../components/Rich-Text-Component/props.json');
const flexGridJSON = require('../../components/Flex-Grid/props.json');
const ctaJSON = require('../../components/Imc-cta/props.json');
const standaloneImageJSON = require('../../components/Standalone-Image-Component/props.json');
const standaloneVideoJSON = require('../../components/Standalone-Video-Component/props.json');
const headingJSON = require('../../components/Heading-Component/props.json');

export function newComponent(json) {
    let component = JSON.parse(JSON.stringify(json));
    return component;
};

export function buildClassesFromProps(props) {
    let classes = "";
    if (props?.length > 0) {
        classes = props.map((c) => {
            if (c.fields?.className?.value) {
                return c.fields.className.value;
            }
        }).join(" ");
    }
    return classes;
}

export function contentSectionComponentRendering() {
    return newComponent(sectionJSON);
}

export function richTextRendering() {
    return newComponent(richTextJSON);
}

export function flexGridRendering() {
    return newComponent(flexGridJSON);
}

export function imcCtaRendering() {
    return newComponent(ctaJSON);
}

export function standaloneImageRendering() {
    return newComponent(standaloneImageJSON);
}

export function standaloneVideoRendering() {
    return newComponent(standaloneVideoJSON);
}

export function headingRendering() {
    return newComponent(headingJSON);
}

export function returnMediaSection(imageProps, videoFileProps, imageWrapper, CTA, linkImage, secondaryCTA = null, secondaryLinkImage = null) {
    let image = standaloneImageRendering();
    let video = standaloneVideoRendering();
    let section = contentSectionComponentRendering();
    let addLink = (linkImage?.value && CTA?.value?.href) ? true : false;

    if (imageWrapper?.length > 0) {
        let backgroundColor = "";
        let extraClass = "";
        imageWrapper.forEach((c) => {
            if (c?.fields?.className?.value.includes("BACKGROUND")) {
                backgroundColor += " " + c.fields.className.value;
            }  else if (c?.fields?.className?.value) {
                extraClass += " " + c.fields.className.value;
            }
        })
        section.fields.backgroundColor.value += backgroundColor;
        section.fields.extraClass.fields.className.value += extraClass;
    }
    if (videoFileProps && videoFileProps.value && videoFileProps.value.href) {
        section.fields.extraClass.fields.className.value = section.fields.extraClass.fields.className.value.replace("crop-circles", "crop-circles crop-circles-video")
        video.fields.placeholderImage = imageProps;
        video.fields.videoLink.value = videoFileProps.value.href;
        section.placeholders['imc-jss-content-section'].push(video);
        return section;
    } else if (imageProps)  {
        image.fields.image = imageProps;
        if (linkImage?.value && CTA?.value?.href) {
            image.fields.link.value = newComponent(CTA.value);
        } else if (secondaryLinkImage?.value && secondaryCTA?.value?.href) {
            image.fields.link.value = newComponent(secondaryCTA.value);
        }
        section.placeholders['imc-jss-content-section'].push(image);
        return section;
    }
    return section;

}

export function returnRichTextHeading(headline, headingExtraClasses, h = "2", CTA = null, linkHeadline = false) {
    let rictText = richTextRendering();
    let extraClasses = "";
    let link = (linkHeadline?.value && CTA?.value?.href);

    if (headingExtraClasses?.length > 0) {
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value.includes("HEADING-")) {
                h = c.fields.className.value.replace("HEADING-", "");
            }  else if (c?.fields?.className?.value) {
                extraClasses += " " + c.fields.className.value;
            }
        });
    }
    rictText.fields.content.value = `${(link) ? `<a href="${CTA.value.href}">` : ``}<h${h} class='${extraClasses}'>${headline}</h${h}>${(link) ? `</a>` : ``}`;
    return rictText;
}

export function returnHeading(fields, secondary = false, overrideSecondary = false) {
    let headline = headingRendering();
    let headingExtraClasses = (!secondary) ? newComponent(fields.headingExtraClasses) : [];
    if (secondary && fields?.secondaryHeadingExtraClasses?.length > 0) {
        headingExtraClasses = newComponent(fields.secondaryHeadingExtraClasses);
    }
    if (fields?.headlineOverride?.value && !secondary) {
        headline.fields.headlineOverride.value = fields.headlineOverride.value;
    }
    if (fields?.headlineOverride?.value && secondary && overrideSecondary) {
        headline.fields.headlineOverride.value = fields.headlineOverride.value;
    }
    if (headingExtraClasses?.length > 0) {
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                headline.fields.headingExtraClasses.push({
                    fields: {
                        className: {
                            value: c.fields.className.value
                        }
                    }
                });
                if (c.fields.className.value.includes("spread-lines")) {
                    headline.fields.desktop.value = "1";
                    headline.fields.tablet.value = "1";
                    headline.fields.mobile.value = "1";
                }
            }
        });
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                if (c.fields.className.value.includes("andmore-line-") && !c.fields.className.value.includes("desktop-") && !c.fields.className.value.includes("tablet-") && !c.fields.className.value.includes("mobile-")) {
                    headline.fields.desktop.value = c.fields.className.value.split("andmore-line-")[1];
                    headline.fields.tablet.value = c.fields.className.value.split("andmore-line-")[1];
                    headline.fields.mobile.value = c.fields.className.value.split("andmore-line-")[1];
                }
            }
        });
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                if (c.fields.className.value.includes("andmore-line-desktop-")) {
                    headline.fields.desktop.value = c.fields.className.value.split("andmore-line-desktop-")[1];
                }
                if (c.fields.className.value.includes("andmore-line-tablet-")) {
                    headline.fields.tablet.value = c.fields.className.value.split("andmore-line-tablet-")[1];
                }
                if (c.fields.className.value.includes("andmore-line-mobile-")) {
                    headline.fields.mobile.value = c.fields.className.value.split("andmore-line-mobile-")[1];
                }
            }
        });
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                if (c.fields.className.value.includes("andmore-width-") && !c.fields.className.value.includes("desktop-") && !c.fields.className.value.includes("tablet-") && !c.fields.className.value.includes("mobile-")) {
                    headline.fields.desktopWidth.value = c.fields.className.value.split("andmore-width-")[1];
                    headline.fields.tabletWidth.value = c.fields.className.value.split("andmore-width-")[1];
                    headline.fields.mobileWidth.value = c.fields.className.value.split("andmore-width-")[1];
                }
            }
        });
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                if (c.fields.className.value.includes("andmore-width-desktop-")) {
                    headline.fields.desktopWidth.value = c.fields.className.value.split("andmore-width-desktop-")[1];
                }
                if (c.fields.className.value.includes("andmore-width-tablet-")) {
                    headline.fields.tabletWidth.value = c.fields.className.value.split("andmore-width-tablet-")[1];
                }
                if (c.fields.className.value.includes("andmore-width-mobile-")) {
                    headline.fields.mobileWidth.value = c.fields.className.value.split("andmore-width-mobile-")[1];
                }
            }
        });
        headingExtraClasses.forEach((c) => {
            if (c?.fields?.className?.value) {
                if (c.fields.className.value.includes("andmore-align-")) {
                    headline.fields.align.value = c.fields.className.value.split("andmore-align-")[1];
                }
            }
        });
    }
    headline.fields.text.value = (secondary) ? fields.subheading.value : fields.headline.value;
    if (!secondary && fields.linkHeadline?.value && fields.primaryCTA?.value?.href) {
        headline.fields.link = fields.primaryCTA;
    } else if (!secondary && fields.linkHeadlineSecondary?.value && fields.secondaryCTA?.value?.href) {
        headline.fields.link = fields.secondaryCTA;
    }
    return headline;
}

export function contentColumnSectionRendering(fields, renderCTAs = true, skipHeadline = false) {
    let section = contentSectionComponentRendering();
    let headline = returnHeading(fields);
    let subheading = richTextRendering();
    let subheadline = headingRendering();
    let copy = richTextRendering();
    let ctaSection = contentSectionComponentRendering();
    let primaryCTA = imcCtaRendering();
    let secondaryCTA = imcCtaRendering();
    // -------------------- //
    // build content column //
    // -------------------- //
    // heading
    if (!skipHeadline && fields?.headline?.value) section.placeholders['imc-jss-content-section'].push(headline);

    subheading.fields.content.value += `<span></span>`;
    if (fields?.subheading?.value && !fields?.secondaryHeadingExtraClasses) {
        subheading.fields.content.value += `<p><span class="andmore-grotesque">${fields.subheading.value}</span></p>`;
    }
    if (fields?.headline?.value || fields?.subheading?.value) {
        if ((fields?.body?.value) || (renderCTAs && (fields.primaryCTA?.value?.href) || (fields.secondaryCTA?.value?.href))) {
            subheading.fields.extraClass = {
                fields: {
                    className: {
                        value: "imc-section--margin-bottom-large"
                    }
                }
            }
        }
        if (fields?.subheading?.value && fields?.secondaryHeadingExtraClasses?.length >= -1) {
            if (!skipHeadline && fields?.headline?.value) section.placeholders['imc-jss-content-section'].push(subheading);
            subheadline = returnHeading(fields, true, skipHeadline);
            section.placeholders['imc-jss-content-section'].push(subheadline);
        }
        section.placeholders['imc-jss-content-section'].push(subheading);
    }
    // copy
    if (fields?.body?.value) {
        copy.fields.content.value = `${fields.body.value}`;
        if (((fields.primaryCTA?.value?.href) || (fields.secondaryCTA?.value?.href)) && renderCTAs) {
            copy.fields.extraClass = {
                fields: {
                    className: {
                        value: "imc-section--margin-bottom-large"
                    }
                }
            }
        }
        section.placeholders['imc-jss-content-section'].push(copy);
    }
    // cta
    if (fields.primaryCTA?.value?.href && renderCTAs) {
        primaryCTA.fields.link = fields.primaryCTA;
        if (fields.primaryCTAclass?.length > 0) {
            primaryCTA.fields.ctaClass.fields.className.value += fields.primaryCTAclass.map((c) => {
                if (c.fields?.className?.value) {
                    return c.fields.className.value;
                }
            }).join(" ");
        }
        ctaSection.placeholders['imc-jss-content-section'].push(primaryCTA);
    }
    if (fields.secondaryCTA?.value?.href && renderCTAs) {
        secondaryCTA.fields.link = fields.secondaryCTA;
        secondaryCTA.fields.ctaClass.fields.className.value = 'imc-button imc-button--primary-inverted';
        if (fields.secondaryCTAclass?.length > 0) {
            secondaryCTA.fields.ctaClass.fields.className.value += fields.secondaryCTAclass.map((c) => {
                if (c.fields?.className?.value) {
                    return c.fields.className.value;
                }
            }).join(" ");
        }
        ctaSection.placeholders['imc-jss-content-section'].push(secondaryCTA);
    }
    ctaSection.fields.extraClass.fields.className.value = " imc-section--cta-wrapper";
    section.placeholders['imc-jss-content-section'].push(ctaSection);
    // extra classes
    if (fields.bodyClasses?.length > 0 && section.fields.extraClass?.fields?.className?.value != null) {
        let extraClass = "" + section.fields.extraClass.fields.className.value;
        section.fields.extraClass.fields.className.value += fields.bodyClasses.map((c) => {
            if (c.fields?.className?.value) {
                return c.fields.className.value.replace("CONTENT","");
            }
        }).join(" ");
    }

    return section;
    // -------------------- //
}
import React, { useState, useEffect, useLayoutEffect, useRef, RefObject, useCallback } from "react";
import { withRouter, RouteComponentProps, useLocation } from 'react-router';
import Link from '../Link';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';
import SvgIcon from 'modules/svgicon';
import { capitalizeEveryWord, MobileNavigationMenu } from "./interface";
import SubNavMenu from "./subNavMenu";
import { last, set } from "lodash";

interface TopHeaderComponentProps extends RouteComponentProps<any> {
    isDrawerOpen: boolean;
    toggleDrawer: (event: any, open: boolean) => void;
    navMenu: MobileNavigationMenu;
}

const MobileNavMenu: React.FC<TopHeaderComponentProps> = ({isDrawerOpen, toggleDrawer, navMenu, location}) => {

    const [activeMenu, setActiveMenu] = useState<any>(null);
    const [lastActiveMenu, setLastActiveMenu] = useState<any>(null);
    const [activeSubMenu, setActiveSubMenu] = useState<any>(null);
    const [lastActiveSubMenu, setLastActiveSubMenu] = useState<any>(null);
    const prevActiveSubMenu = useRef<any>(null);
    const [drawerState, setDrawerState] = useState<any>(isDrawerOpen);
    const isLoggedIn = ((Environment.isHot != undefined) || UserUtility.isLoggedIn());
    const collapseWrapperRef = useRef<HTMLDivElement>(null);
    const firstItem = useRef<HTMLButtonElement>(null);
    const lastItem = useRef<HTMLElement>();
    const exitItem = useRef<HTMLButtonElement>(null);
    const firstSubItem = useRef<HTMLButtonElement>(null);
    const lastSubItem = useRef<HTMLElement>();
    const user = isLoggedIn ? UserUtility.getUserData() : {
        firstName: "George",
        lastName: "Burdell"
    };
    const name = (user && user.firstName && user.lastName) ? `${user.firstName} ${user.lastName.charAt(0)}.` : `George B.`;

    useEffect(() => {
        setActiveMenu(null);
        setActiveSubMenu(null);
    }, [location])

    useEffect(() => {
        if (isDrawerOpen !== drawerState) {
            if (isDrawerOpen === false) {
                setTimeout(() => {
                    setDrawerState(isDrawerOpen);
                    setActiveMenu(null);
                    setActiveSubMenu(null);
                    setLastActiveMenu(null);
                    setLastActiveSubMenu(null);
                }, 500); // 500 milliseconds = 0.5 seconds
            } else {
                setDrawerState(isDrawerOpen);
                firstItem.current?.focus();
            }
        }
    }, [isDrawerOpen]);

    useLayoutEffect(() => {

        const handleKeyDown = (event: KeyboardEvent) => {

            const lastSubElement = document.querySelector('.last-sub-item') as HTMLElement;

            if (event.key === 'Tab') {
                if (event.shiftKey) {
                    // Shift + Tab
                    if (document.activeElement === firstItem.current || document.activeElement === firstSubItem.current) {
                        event.preventDefault();
                        exitItem.current?.focus();
                    } else if (document.activeElement === exitItem.current) {
                        event.preventDefault();
                        if (activeSubMenu !== null && lastSubElement) {
                            event.preventDefault();
                            lastSubElement.focus();
                        } else {
                            lastItem.current?.focus();
                        }
                    }
                } else {
                    // Tab
                    if (document.activeElement === lastItem.current || document.activeElement === lastSubElement) {
                        event.preventDefault();
                        exitItem.current?.focus();
                    } else if (document.activeElement === exitItem.current && activeSubMenu !== null) {
                        event.preventDefault();
                        firstSubItem.current?.focus();
                    }
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeMenu, activeSubMenu]);  

    useLayoutEffect(() => {

        setTimeout(() => {
            if (prevActiveSubMenu.current !== activeSubMenu && activeSubMenu !== null) {
                firstSubItem.current?.focus();
            }
            prevActiveSubMenu.current = activeSubMenu;
        }, 300);
        
    }, [activeMenu, activeSubMenu, lastSubItem]);  

    const handleClick = (event: any, isOpen: null, menu: number|null, subMenu: number|null) => {
        if (menu === null && subMenu === null) {
            const lastElement = document.querySelector(`.mobile-menu-${activeMenu}-${activeSubMenu}`) as HTMLElement;
            lastElement?.focus();
        }
        if (event) setActiveMenu(menu);
        if (event && menu !== null) setLastActiveMenu(menu);
        if (event) setActiveSubMenu(subMenu);
        if (event && subMenu !== null) setLastActiveSubMenu(subMenu);
    }

    return (
        <>
            <div className={`top-nav-overlay imc-modal--overlay ${isDrawerOpen ? 'active' : ''}`}
                onClick={(event?: any) => {
                    handleClick(event, null, null, null);
                    toggleDrawer(event, false);
                }}
            ></div>
            <div className={`top-nav-dropdown left ${isDrawerOpen ? `active`: ``} top-nav-drawer drawer-menu`}>
                <div className="top-nav-dropdown-menu top-nav-plan">
                    <div className="top-nav-bottom-button">
                        <div className={`top-nav-plan-header`}>
                            <div className="top-nav-column">
                                <div className='top-nav-item-link'>
                                    <div className='top-nav-item-heading-wrapper imc-gallery--align-flex-start'>
                                        <div className={`imc-section--padded-top-small`}>
                                            {(!isLoggedIn) && <h2 className={`imc-type--title-3 imc-content--small-line-height`}>
                                                <span className='imc-content--thin imc-content--tight-letter-spacing imc-content--to-upper-case'>{navMenu.title}</span>
                                                <br /><span className='imc-content--bold'>MENU</span>
                                            </h2>}
                                            {(isLoggedIn) && <div className={` imc-content--small-line-height`}>
                                                <span className={`imc-content--bold imc-type--color-darkestgray`}>Welcome</span>
                                                <h2 className={`imc-type--title-3 imc-content--small-line-height`}>
                                                    <span className='imc-content--bold'>{name}</span>
                                                </h2>
                                            </div>}
                                        </div>
                                        {(!isLoggedIn && navMenu?.signIn?.title) && <div>
                                            <a
                                                href={navMenu.signIn.field.value.href}
                                                className={`imc-button imc-button--small imc-button--small-xy imc-button--radius-3 imc-button--atmarket-gray imc-section--padded-left-medium imc-section--padded-right-medium`}  onClick={(event?: any) => {}}>
                                                {navMenu.signIn.title}
                                            </a>
                                        </div>}
                                        <div>
                                            <button
                                                className={`imc-button imc-button--small imc-button--small-xy imc-button--radius-3 imc-button--atmarket-gray imc-button--transparent`}
                                                onClick={(event?: any) => {
                                                    handleClick(event, null, null, null);
                                                    toggleDrawer(event, false);
                                                }}
                                                ref={exitItem}
                                            >
                                                <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-orange-hover`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`top-nav-dropdown-grid imc-content--relative ${(activeMenu != null || drawerState != isDrawerOpen) ? `active`: ``}`}>
                            <div className="top-nav-column">
                                {navMenu.menus.map((menu: any, m: number) => {
                                    return (
                                        <div className={`${((m === navMenu.menus.length - 1) && ((isLoggedIn && navMenu?.signOut?.title) || navMenu?.userLinks)) ? 'imc-content--display-flex-grow-1' : ''}`}>
                                            {(menu?.title) && <h3 className={`imc-section--padded-left-mediumsmall imc-section--padded-right-mediumsmall imc-section--padded-bottom-large`}>{menu.title}</h3>}
                                            <ul className="top-nav-plan-list">
                                                {menu.menus.map((item: any, f: number) => {
                                                    return (
                                                        <li key={`${m}-${f}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                            <DropdownMenu
                                                                buttonClassName={`mobile-menu-${m}-${f} imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                key={`top-nav-plan`}
                                                                label={item.title}
                                                                chevron={false}
                                                                icon={`juniper-right-chevron`}
                                                                color={`svg-black svg-black-hover`}
                                                                isDrawer={false}
                                                                openState={false}
                                                                width={12}
                                                                height={12}
                                                                rightIcon={true}
                                                                clickHandler={(event?: any) => {
                                                                    handleClick(event, null, m, f)
                                                                }}
                                                                escapeHandler={(event?: any) => {
                                                                    handleClick(event, null, null, null)
                                                                }}
                                                                foriegnContent={true}
                                                                buttonRef={(m === 0 && f === 0) ? firstItem : undefined}
                                                            >
                                                                <></>
                                                            </DropdownMenu>
                                                        </li>
                                                    );
                                                })}
                                                {menu.links.map((item: any, f: number) => {
                                                    return (
                                                        <li key={`${m}-${f}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                            <Link
                                                                className={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                field={item.field}
                                                                label={item.title}
                                                                icon={`juniper-right-chevron`}
                                                                color={`svg-black svg-black-hover`}
                                                                onClick={(event: any) => {
                                                                    toggleDrawer(event, false);
                                                                }}
                                                            >
                                                                <span>{item.title}</span>
                                                                {(item.field.value.linktype != 'internal') && <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}
                                {((isLoggedIn && navMenu?.signOut?.title) || navMenu?.userLinks) && <div>
                                    <ul className="top-nav-plan-list imc-content--display-flex imc-content--display-flex-wrap">
                                        {(isLoggedIn && navMenu?.signOut?.title) && <li key={`sign-out`} className="top-nav-plan-list-item top-nav-mobile-menu imc-content--full-width">
                                            <Link
                                                href={navMenu.signOut.field.value.href}
                                                className={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-button--left imc-section--padded-left-none imc-section--padded-right-none`} 
                                                onClick={(event?: any) => {
                                                    event.preventDefault();
                                                    window.location.href = navMenu?.signOut?.field.value.href;
                                                }}
                                                ref={!(navMenu?.userLinks && navMenu?.userLinks?.length > 0) ? lastItem as unknown as RefObject<typeof Link> : undefined}
                                            >
                                                {navMenu.signOut.title}
                                            </Link>
                                        </li>}
                                        {(navMenu?.userLinks) && navMenu.userLinks.map((item: any, u: number) => {
                                            return (
                                                <li key={`user-${u}`} className="top-nav-plan-list-item top-nav-mobile-menu imc-content--full-width">
                                                    <Link
                                                        className={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-button--left imc-section--padded-left-none imc-section--padded-right-none`}
                                                        field={item.field}
                                                        label={item.title}
                                                        icon={`juniper-right-chevron`}
                                                        color={`svg-black svg-black-hover`}
                                                        onClick={(event: any) => {
                                                            toggleDrawer(event, false);
                                                        }}
                                                        ref={(navMenu?.userLinks && u == navMenu?.userLinks?.length - 1) ? lastItem as unknown as RefObject<typeof Link> : undefined}
                                                    >
                                                        <span>{item.title}</span>
                                                        {(item.field.value.linktype != 'internal') && <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>}
                            </div>
                            <div className="top-nav-column" ref={collapseWrapperRef}>
                                {(lastActiveMenu !== null && lastActiveSubMenu !== null && navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.links) && 
                                    <div>
                                        {(lastActiveMenu !== null && lastActiveSubMenu !== null) && <div key={`${lastActiveMenu}--1`} className="top-nav-plan-list-item top-nav-mobile-menu top-nav-mobile-menu-header imc-section--margin-left-large imc-section--margin-right-large">
                                            <button className="imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none"
                                                onClick={(event: any) => {
                                                    handleClick(event, null, null, null);
                                                }}
                                                ref={firstSubItem}
                                            >Back</button>
                                            <span>/</span>
                                            <div>{(navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.title) && navMenu.menus[lastActiveMenu].menus[lastActiveSubMenu].title}</div>
                                        </div>}
                                        <ul className="top-nav-plan-list" key={`submenu-${lastActiveMenu}-${lastActiveSubMenu}`}>
                                            {(lastActiveMenu !== null && lastActiveSubMenu !== null && navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.links) && navMenu.menus[lastActiveMenu].menus[lastActiveSubMenu].links.map((item: any, i: number) => {
                                                const lastSubMenu = ((i + 1) === navMenu.menus[lastActiveMenu].menus[lastActiveSubMenu].links?.length);
                                                return (
                                                    <>
                                                    {(item?.dates || item?.description) && <li key={`li-${lastActiveMenu}-${i}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                                <DropdownMenu
                                                                    buttonClassName={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                    key={`mobile-dropdown-${lastActiveMenu}-${i}`}
                                                                    label={item.title}
                                                                    chevron={false}
                                                                    icon={`juniper-right-chevron`}
                                                                    color={`svg-black svg-black-hover`}
                                                                    isDrawer={false}
                                                                    openState={(i === 0) ? true : false}
                                                                    width={12}
                                                                    height={12}
                                                                    rightIcon={true}
                                                                    isCollapsible={true}
                                                                    wrapperClass={'dropdown-collapsible'}
                                                                    collapseRef={collapseWrapperRef}
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            <div className={`imc-section--padded-bottom-medium`}>
                                                                                {(item?.dates) && <div className={`top-nav-item-subheading imc-content--bold`}><span className={`imc-type--color-orange`}>{item.dates}</span></div>}
                                                                                {(item?.description) && <div className={`top-nav-item-description imc-type--color-neutral-heaviest`}>{item.description}</div>}
                                                                                <Link
                                                                                    className={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-button--left imc-section--padded-left-none imc-section--padded-right-none`}
                                                                                    field={item.field}
                                                                                    label={item.title}
                                                                                    icon={`juniper-right-chevron`}
                                                                                    color={`svg-black svg-black-hover`}
                                                                                    onClick={(event: any) => {
                                                                                        toggleDrawer(event, false);
                                                                                    }}
                                                                                >
                                                                                    <span>{item.link}</span>
                                                                                    {(item.field.value.linktype != 'internal') && <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </li>
                                                    }
                                                    {!(item?.dates || item?.description) && <li key={`li-${lastActiveMenu}-${i}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                                <Link
                                                                    className={`${(lastSubMenu) ? 'last-sub-item ' : ''}imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                    field={item.field}
                                                                    label={item.title}
                                                                    icon={`juniper-right-chevron`}
                                                                    color={`svg-black svg-black-hover`}
                                                                    onClick={(event: any) => {
                                                                        toggleDrawer(event, false);
                                                                    }}
                                                                >
                                                                    <span>{item.title}</span>
                                                                    {(item.field.value.linktype != 'internal') && <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />}
                                                                </Link>
                                                            </li>
                                                    }
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                                {(lastActiveMenu !== null && lastActiveSubMenu !== null && navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.menus?.length) && (navMenu.menus[lastActiveMenu].menus[lastActiveSubMenu].menus as any[]).map((subMenu: any, s: number) => {
                                    return (
                                        <div className={`imc-content--display-flex-grow-0`}>
                                            {(s === 0 && lastActiveMenu !== null && lastActiveSubMenu !== null) && <div key={`${lastActiveMenu}--1`} className="top-nav-plan-list-item top-nav-mobile-menu top-nav-mobile-menu-header imc-section--margin-left-large imc-section--margin-right-large">
                                                <button className="imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none"
                                                    onClick={(event: any) => {
                                                        handleClick(event, null, null, null);
                                                    }}
                                                    ref={firstSubItem}
                                                >Back</button>
                                                <span>/</span>
                                                <div>{(navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.title) && navMenu.menus[lastActiveMenu].menus[lastActiveSubMenu].title}</div>
                                            </div>}
                                            {(subMenu?.title) &&
                                            <div className={`imc-section--padded-left-large imc-section--padded-right-large`}>
                                                <h3 className={`imc-section--padded-bottom-medium ${(s === 0) ? `imc-section--padded-top-medium` : ``}`}>{capitalizeEveryWord(subMenu.title)}</h3>
                                            </div>}
                                            <ul className="top-nav-plan-list" key={`submenu-${lastActiveMenu}-${lastActiveSubMenu}`}>
                                                {(subMenu?.links) && subMenu.links.map((item: any, i: number) => {
                                                    const last = (((s + 1) === navMenu?.menus[lastActiveMenu]?.menus[lastActiveSubMenu]?.menus?.length) && (i === subMenu?.links?.length - 1));
                                                    if (item?.dates || item?.description) {
                                                        return (
                                                            <li key={`li-${lastActiveMenu}-${s}-${i}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                                <DropdownMenu
                                                                    buttonClassName={`imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                    key={`mobile-dropdown-${lastActiveMenu}-${i}`}
                                                                    label={item.title}
                                                                    chevron={false}
                                                                    icon={`juniper-right-chevron`}
                                                                    color={`svg-black svg-black-hover`}
                                                                    isDrawer={false}
                                                                    // openState={(i === 0 && s === 0) ? true : false}
                                                                    width={12}
                                                                    height={12}
                                                                    rightIcon={true}
                                                                    isCollapsible={true}
                                                                    wrapperClass={'dropdown-collapsible'}
                                                                    collapseRef={collapseWrapperRef}
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            <div className={`imc-section--padded-bottom-medium`}>
                                                                                {(item?.dates) && <div className={`top-nav-item-subheading imc-content--bold`}><span className={`imc-type--color-orange`}>{item.dates}</span></div>}
                                                                                {(item?.description) && <div className={`top-nav-item-description imc-type--color-neutral-heaviest`}>{item.description}</div>}
                                                                                <Link
                                                                                    className={`${(last) ? 'last-sub-item ' : ''}imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-button--left imc-section--padded-left-none imc-section--padded-right-none`}
                                                                                    field={item.field}
                                                                                    label={item.title}
                                                                                    icon={`juniper-right-chevron`}
                                                                                    color={`svg-black svg-black-hover`}
                                                                                    onClick={(event: any) => {
                                                                                        toggleDrawer(event, false);
                                                                                    }}
                                                                                >
                                                                                    <span>{item.link}</span>
                                                                                    <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field?.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </li>
                                                        )
                                                    } else {
                                                        return (
                                                            <li key={`li-${lastActiveMenu}-${s}-${i}`} className="top-nav-plan-list-item top-nav-mobile-menu">
                                                                <Link
                                                                    className={`${(last) ? 'last-sub-item ' : ''}imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none`}
                                                                    field={item.field}
                                                                    label={item.title}
                                                                    icon={`juniper-right-chevron`}
                                                                    color={`svg-black svg-black-hover`}
                                                                    onClick={(event: any) => {
                                                                        toggleDrawer(event, false);
                                                                    }}
                                                                >
                                                                    <span>{item.title}</span>
                                                                    <SvgIcon width={12} height={12} xlinkHref={`#juniper-${(item.field.value.linktype != 'internal') ? 'open-link' : 'forward-arrow'}`} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-black-hover`} />
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(MobileNavMenu);
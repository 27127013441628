import * as types from './actionTypes';
import TypeAheadSearchAPI from '../api/search';
import { sanatizeSearchQuery } from 'utils/search';

/**
 * Initialize the TypeAheadSearchAPI
 * @type {TypeAheadSearchAPI}
 * @private
 */
const _TypeAheadSearchAPI = new TypeAheadSearchAPI();
let latestRequest = null;
/**
 * Action to get the search results
 * @param {string} query Query to run
 * @returns {Function} Promise object with fetch to the TypeAheadSearchAPI
 */
export function getResults(query, exhibitorId, searchTypes=[], pageId) {
    return function returnResults(dispatch) {
        if (query.length > 0) {
            // eslint-disable-next-line no-multi-assign
            if (query.endsWith('\\')) {
                query = query.slice(0, -1);
            }
            query = sanatizeSearchQuery(query);
            const request = latestRequest = _TypeAheadSearchAPI.getResults(query, exhibitorId, pageId)
                .then((response) => {
                    if (response) {
                        const typeaheadsearch =  {options : (searchTypes.length>0?response.filter(x=>searchTypes.find(y=> y===x.type)): response), query};

                        if (request === latestRequest) {
                            dispatch({
                                type: types.TYPEAHEADSEARCH_SUCCESS,
                                typeaheadsearch,
                                query,
                            });
                        }
                        return typeaheadsearch;
                    } else {
                        dispatch({
                            type: types.TYPEAHEADSEARCH_FAIL,
                        });
                        return response;
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: types.TYPEAHEADSEARCH_FAIL,
                    });
                    return error;
                });
            return request;
        }
    };
}

/**
 * Action to reset the search results
 * @returns {Function} Promise object with fetch to the TypeAheadSearchAPI
 */
export function resetResult() {
    return dispatch => dispatch({
        type: types.TYPEAHEADSEARCH_RESET,
    });
}

export function setNewQuery(query) {
    return dispatch => dispatch({
        type: types.TYPEAHEADSEARCH_UPDATE_CURRENT_QUERY,
        query,
    });
}
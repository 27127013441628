import React, { useState, useEffect, useLayoutEffect, useRef, RefObject, useCallback } from "react";
import { withRouter, RouteComponentProps, useLocation } from 'react-router';
import TypeAheadSearch from '../Type-Ahead-Search';
import Link from '../Link';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';
import SvgIcon from 'modules/svgicon';
import { capitalizeEveryWord, MobileNavigationMenu } from "./interface";
import SubNavMenu from "./subNavMenu";
import { last, set } from "lodash";

interface SearchDrawerProps extends RouteComponentProps<any> {
    isDrawerOpen: boolean;
    toggleDrawer: (event: any, open: boolean) => void;
    title: string;
    rendering: any;
    searchPlaceholders?: any;
}

interface ExtendedHTMLInputElement extends HTMLInputElement {
    input?: HTMLInputElement;
  }

const searchFieldDefaults = {
  "showMultiSelect": { "value": false },
  "showSort": { "value": false },
  "showShare": { "value": false },
  "disableTypeahead": { "value": false },
  "showClearSearch": { "value": false },
  "maxLength": { "value": "" },
  "hideSubmitButton": { "value": false },
  "minLengthForSearch": { "value": "3" },
  "showSavedSearches": { "value": false },
  "extraClass": { "value": "imc-searchform--global" },
  "resultsRedirectUrl": { "value": "/search/exhibitors" },
  "showMessage": { "value": false },
  "hideSortResultBy": { "value": false },
  "searchType": [],
  "sortResultsBy": [],
  "hideFilterBy": { "value": false },
  "hideSearchType": { "value": false }
}

const SearchDrawer: React.FC<SearchDrawerProps> = ({isDrawerOpen, toggleDrawer, title, rendering, searchPlaceholders}) => {

    const [drawerState, setDrawerState] = useState<any>(isDrawerOpen);
    const collapseWrapperRef = useRef<HTMLDivElement>(null);
    const firstItem = useRef<HTMLButtonElement>(null);
    const lastItem = useRef<HTMLElement>();
    const exitItem = useRef<HTMLButtonElement>(null);
    const firstSubItem = useRef<HTMLButtonElement>(null);
    const lastSubItem = useRef<HTMLElement>();
    const searchRef = useRef<ExtendedHTMLInputElement | null>(null);

    let searchFields = {};
    if (rendering.placeholders['imc-header-placeholder'][0].fields) {
        searchFields = {
            ...searchFieldDefaults,
            ...rendering.placeholders['imc-header-placeholder'][0].fields
        };
    }

    useEffect(() => {
        if (isDrawerOpen !== drawerState) {
            if (isDrawerOpen === false) {
                setTimeout(() => {
                    setDrawerState(isDrawerOpen);
                }, 500);
            } else {
                setDrawerState(isDrawerOpen);
                if (searchRef?.current?.input?.focus) searchRef.current.input.focus();
            }
        }
    }, [isDrawerOpen]);

    useLayoutEffect(() => {

        const handleKeyDown = (event: KeyboardEvent) => {

            const lastSubElement = document.querySelector('.last-sub-item') as HTMLElement;

            if (event.key === 'Tab') {
                if (event.shiftKey) {
                    // Shift + Tab
                    if (document.activeElement === firstItem.current || document.activeElement === firstSubItem.current) {
                        event.preventDefault();
                        exitItem.current?.focus();
                    } else if (document.activeElement === exitItem.current) {
                        event.preventDefault();
                        if (lastSubElement) {
                            event.preventDefault();
                            lastSubElement.focus();
                        } else {
                            lastItem.current?.focus();
                        }
                    }
                } else {
                    // Tab
                    if (document.activeElement === lastItem.current || document.activeElement === lastSubElement) {
                        event.preventDefault();
                        exitItem.current?.focus();
                    } else if (document.activeElement === exitItem.current) {
                        event.preventDefault();
                        firstSubItem.current?.focus();
                    }
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <div className={`top-nav-overlay imc-modal--overlay ${isDrawerOpen ? 'active' : ''}`}
                onClick={(event?: any) => {
                    toggleDrawer(event, false);
                }}
            ></div>
            <div className={`top-nav-dropdown right ${isDrawerOpen ? `active`: ``} top-nav-drawer drawer-menu search-drawer`}>
                <div className="top-nav-dropdown-menu top-nav-plan">
                    <div className="top-nav-bottom-button">
                        <div className={`top-nav-plan-header`}>
                            <div className="top-nav-column">
                                <div className='top-nav-item-link'>
                                    <div className='top-nav-item-heading-wrapper imc-gallery--align-flex-start'>
                                        <div className={`imc-section--padded-top-small`}>
                                            <h2 className={`imc-type--title-3 imc-content--small-line-height`}>
                                                <span className='imc-content--thin imc-content--tight-letter-spacing imc-content--to-upper-case imc-content--visibility-hidden'>{title}</span>
                                                <br /><span className='andmore-bold'>SEARCH</span>
                                            </h2>
                                        </div>
                                        <div>
                                            <button
                                                className={`imc-button imc-button--small imc-button--small-xy imc-button--radius-3 imc-button--atmarket-gray imc-button--transparent`}
                                                onClick={(event?: any) => {
                                                    toggleDrawer(event, false);
                                                }}
                                                ref={exitItem}
                                            >
                                                <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black imc-section--margin-xsmall imc-section--margin-left-xsmall imc-section--margin-right-xsmall`} useClassName={`svg-black svg-orange-hover`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`top-nav-dropdown-grid imc-content--relative`}>
                            <div className="top-nav-column">
                                <div className={`imc-section--padded-left-large imc-section--padded-right-large`}>
                                    <TypeAheadSearch
                                        btnClickRequired="false"
                                        // navContainer={navContainer}
                                        mobileSearchButton={true}
                                        fields={searchFields}
                                        // onAfterSubmit={closeSearch}
                                        extraFormClass="imc-searchform--mobile imc-searchform--drawer"
                                        searchRef={searchRef}
                                        showClearSearch={{
                                            value: true,
                                        }}
                                        forceShowClearSearch={true}
                                        extraContainerClass={`suggestions-container--mobile`}
                                        extraClass={`imc-searchform--mobile`}
                                        closeIcon={`juniper-close-v2`}
                                        closeIconSize={20}
                                        alwaysRenderSuggestions={true}
                                        isDrawer={true}
                                        onAfterSubmit={() => {
                                            toggleDrawer(null, false);
                                        }}
                                        clearText={`Clear`}
                                        hideClearOnEmpty={true}
                                        placeholders={searchPlaceholders}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(SearchDrawer);
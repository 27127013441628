import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSitecoreApiHost } from '../../AppGlobals';
import Moment from 'react-moment';
import Link from '../Link';
import ShowroomListItem from './ShowroomListItem';
import SvgIcon from 'modules/svgicon';
import { CountedItem } from './helpers';
import { analytics } from '../../modules/marketplan/helpers/analytics';
import UserUtility from "utils/userutility/index";
import { Environment } from '../../Environment';
import { richTextRendering } from 'utils/buildRenderingParameters';
import RichTextComponent from '../Rich-Text-Component';
import { ShowroomInterface } from 'modules/marketplan/reducers/interface';


interface MarketPlanProps {
    fields: any;
    toggleDrawer: any;
    drawerRef?: any;
    dropDownRef?: any;
}

interface MessageInterface {
    heading: string;
    copy: string;
    type: string,
}
interface EmpyStateInterface {
    messages: MessageInterface[];
}

const MarketPlan: React.FC<MarketPlanProps> = ({fields, toggleDrawer, drawerRef, dropDownRef}) => {

    const isLoggedIn = Environment.isHot != undefined || UserUtility.isLoggedIn();
    const { 
        authenticatedBody,
        authenticatedHeading,
        unauthenticatedBody,
        unauthenticatedHeading,
        unregisteredBody,
        unregisteredHeading,
        helpLink,
        buyerPortalDomain
    } = fields.data.model;
    const [emptyState, setEmptyState] = useState<EmpyStateInterface>();
    const dispatch = useDispatch();
    const plan = useSelector((state: any) => state.marketPlanReducer);
    const [showrooms, updateShowrooms] = useState<ShowroomInterface[]>([]);
    const [isScrollable, setIsScrollable] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    let start: Date = new Date(plan.market.from);  
    let end: Date = new Date(plan.market.to);  
    let visited = plan.showrooms.filter((showroom: any) => showroom.lastVisited).length;

    const checkScrollable = (event: any) => {
        if (listRef.current) {
            setIsScrollable(listRef.current.scrollHeight > listRef.current.clientHeight);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            if (!plan.isRegistered) {
                setEmptyState({
                    messages: [
                        {
                            heading: unregisteredHeading,
                            copy: unregisteredBody,
                            type: 'register',
                        },
                    ]
                });
            } else {
                setEmptyState({
                    messages: [
                        {
                            heading: authenticatedHeading,
                            copy: authenticatedBody,
                            type: 'signin',
                        }
                    ]
                });
            }
        } else {

            setEmptyState({
                messages: [
                    {
                        heading: unregisteredHeading,
                        copy: unregisteredBody,
                        type: 'register',
                    },
                    {
                        heading: unauthenticatedHeading,
                        copy: unauthenticatedBody,
                        type: 'signin',
                    }
                ]
            });
        }
        checkScrollable(undefined);
    }, [dispatch, plan]);

    useEffect(() => {
        if (showrooms?.length > 0 && plan?.showrooms && showrooms.length > plan.showrooms.length) {
            setTimeout(() => {
                updateShowrooms([...plan.showrooms]);
            }, 800);
        } else {
            updateShowrooms([...plan.showrooms]);
        }
    }, [plan.showrooms]);

    useEffect(() => {
        window.addEventListener('resize', checkScrollable);
        return () => {
            window.removeEventListener('resize', checkScrollable);
        };
    }, []);

    return (
        <div className="top-nav-dropdown-menu top-nav-plan" ref={dropDownRef}>
            <div className="top-nav-bottom-button">
                <div className={`top-nav-plan-header ${isScrollable ? 'scrollable' : ''}`}>
                    <div className="top-nav-column">
                        <div className='top-nav-item-link'>
                            <div className='top-nav-item-heading-wrapper imc-gallery--align-flex-start'>
                                <div>
                                    <h2 className={`imc-type--title-3 imc-content--small-line-height`}>
                                        <span className='imc-content--thin imc-content--tight-letter-spacing'>{ plan.market.displayName }</span>
                                        <br /><span className='andmore-bold'>MARKET PLAN</span>
                                    </h2>
                                    <div className={`imc-type--title-6 imc-content--to-upper-case`}>
                                        <Moment format={`${`MMM D`}`}>{plan.market.from}</Moment> - <Moment format={`${`${(start.getMonth() != end.getMonth()) ? 'MMM ' : ''}D`}`}>{plan.market.to}</Moment>
                                    </div>
                                </div>
                                <div>
                                    <button className={`imc-button imc-button--small imc-button--small-xy imc-button--radius-3 top-nav-plan-close`}  onClick={(event?: any) => toggleDrawer(event, false)} ref={drawerRef}>
                                        <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black `} useClassName={`svg-black svg-orange-hover`} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="top-nav-column market-plan--extra-light-gray">
                        <div className={`top-nav-item-subheading top-nav-plan-heading imc-content--bold imc-gallery__item--center`}>
                            <CountedItem count={plan.activitySummary.showroomsCount} title={`Showroom${(plan.activitySummary.showroomsCount != 1) ? 's' : ''}`} />
                            <span className={`top-nav-gray`}>|</span>
                            <CountedItem count={visited} title={`Visited`} />
                            <span className={`top-nav-gray`}>|</span>
                            <CountedItem count={ plan.activitySummary.photosCount } title={`Photo${(plan.activitySummary.photosCount != 1) ? 's' : ''}`} />
                        </div>
                    </div>
                </div>
                <div className="top-nav-dropdown-grid">
                    <div className="top-nav-column" ref={listRef}>
                        <ul className="top-nav-plan-list">
                            {showrooms.length > 0 && showrooms.sort((a: ShowroomInterface , b: ShowroomInterface) => {
                                if (a?.customSort != null && b?.customSort != null && a?.customSort != b?.customSort) {
                                    return a.customSort - b.customSort;
                                }
                                if (a?.createdOn && b?.createdOn) {
                                    return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
                                }
                                return 0;
                            }).map((showroom: any, index: number) => {
                                return (
                                    <ShowroomListItem showroom={showroom} key={showroom.showroomName} index={index} toggleDrawer={toggleDrawer} />
                                );
                            })}
                            {plan.showrooms.length == 0 && (emptyState?.messages) && (emptyState?.messages?.length > 0) && emptyState?.messages?.map((message: any, index: number) => {
                                const richText = richTextRendering();
                                richText.fields.content.value = (message?.copy?.data?.value) ? message.copy.data.value : '';
                                return (
                                    <section className={`top-nav-item-link top-nav-plan-empty-list-wrapper`} key={index}>
                                        <div className={`top-nav-plan-empty-list top-nav-plan-empty-list-${message.type}`}>
                                            {/* <SvgIcon width={58} height={58} xlinkHref={'#juniper-discover-search'} className={``} /> */}
                                            {message?.heading?.data?.value && <div className={`imc-type--title-4 andmore-bold top-nav-plan-empty-list-heading`}><span dangerouslySetInnerHTML={{ __html: message.heading.data.value}} /></div>}
                                            {message?.copy?.data?.value && <div className={`top-nav-plan-empty-list-body`}><RichTextComponent {...richText} rendering={richText} /></div>}
                                        </div>
                                    </section>
                                );
                            })}
                            {isLoggedIn && plan.showrooms.length == 0 &&
                                <section className={`top-nav-item-link imc-section--margin-top-medium imc-content--center`}>
                                    <Link
                                        href={`${buyerPortalDomain.data.value}/marketrecaps`}
                                        target="_blank"
                                        className="imc-content--center-margin imc-button imc-button--atmarket-secondary imc-button--atmarket--sizing imc-button--radius-3 imc-button--small imc-button--full-bleed"
                                        onClick={(event?: any) => toggleDrawer(event, false)}>
                                            <SvgIcon width={16} height={16} className={`top-nav-user-icon imc-content--shift-up-1`} useClassName={`svg-darkblue`} xlinkHref={`#juniper-planner`} />
                                            <span>ALL MARKET RECAPS</span>
                                    </Link>
                                </section>
                            }
                            {(!isLoggedIn || !plan.isRegistered || plan.showrooms.length == 0) && 
                                <section className={`top-nav-item-link imc-section--margin-top-medium imc-content--center`}>
                                    <button className="imc-content--center-margin imc-button imc-button--atmarket-neutral imc-button--atmarket--sizing imc-button--radius-3 imc-button--small imc-button--full-bleed" onClick={(event?: any) => toggleDrawer(event, false)}>GOT IT</button>
                                </section>
                            }
                            {(!isLoggedIn || !plan.isRegistered || plan.showrooms.length == 0) &&  helpLink?.data?.value?.href &&
                                <section className={`top-nav-item-link imc-section--margin-top-medium imc-content--center `}>
                                    <Link className={`top-nav-help`} field={helpLink?.data} />
                                </section>
                            }
                        </ul>
                    </div>
                </div>
                {(isLoggedIn && showrooms && showrooms.length > 0) &&
                    <div className={`imc-section--basic-white top-nav-plan-manage-wrapper ${isScrollable ? 'scrollable' : ''}`}>
                        <Link
                            href={`${buyerPortalDomain.data.value}/markets/${plan.market.marketId}`}
                            target="_blank"
                            className={`imc-button imc-button--atmarket imc-button--full-width imc-button--radius-3 imc-button--transform-none`}
                            onClick={(event?: any) => analytics().track('Plan + Select Market Plan from Preview', {
                                contact_alt_id: plan.contactAltId,
                                market_id: `${plan.market.marketId}`,
                                market_name: plan.market.displayName,
                                url: window.location.href,
                            })}
                        >
                            <span>Manage My Plan</span>
                            <SvgIcon width={12} height={12} xlinkHref={'#juniper-union'} className={`svg-white imc-section--margin-left-small`} useClassName={`svg-white`} />
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
}

export default MarketPlan
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import IsolatedScroll from 'react-isolated-scroll';
import qs from 'query-string';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withRouter } from 'react-router';
import { withSitecoreContext, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
//Utils
import { getLocalRedirectionUrl } from 'utils/getRedirectionUrl';
import { encode, decode } from 'utils/querystring';
import { getDictionaryValue, SEARCH_RESULTS_DICTIONARY_KEY_PREFIX } from 'utils/dictionary';
import { uniqueid } from 'utils/uniqueid';
import { escapeRegexCharacters } from 'utils/escapecharacters';
import { getSearchTypeFromPath } from 'utils/search';
import ImcDataLayer from 'utils/datalayer';
import { padContent, unPadContent } from "utils/scrollhelpers";
import { Environment } from '../../../Environment';
//actions
import * as searchActions from '../actions/searchActions';
import TypeAheadSearchAPI from '../api/search';

//Local Components
import SavedSearchesButton from './SavedSearchesButton.jsx';
import MultiSelectButton from './MultiSelectButton.jsx';
import SortButton from './SortButton.jsx';
import ShareButton from './ShareButton.jsx';

//Modules
import Link from '../../Link';
import SvgIcon from 'modules/svgicon';
import AlertBox from 'modules/alertbox';
import Overlay from 'modules/overlay';
import SearchPubSubService from "modules/search/services/pubsub";
import { pad } from 'lodash';



const DESKTOP_BREAKPOINT = 992;

/**
 * Sets the PropTypes for the form
 * @type {
 * {actions, query: *, placeholderText: *, btnText: *, typeaheadsearch,
 * checkIfSelected: *, customSubmit: *, mobileSearchButton: *, btnClickRequired: *, 
 * onChange: *, maxLength: *, inputId, minLengthForSearch: *, sortType: *, typeCheck: *}}
 */
const propTypes = {
    actions: PropTypes.object.isRequired,
    query: PropTypes.string,
    placeholderText: PropTypes.string,
    btnText: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    typeaheadsearch: PropTypes.object.isRequired,
    checkIfSelected: PropTypes.func,
    customSubmit: PropTypes.func,
    mobileSearchButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    btnClickRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputId: PropTypes.string.isRequired,
    minLengthForSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sortType: PropTypes.bool,
    typeCheck: PropTypes.string,
    showClearSearch: PropTypes.object,
    clearSearchTitle: PropTypes.string,
    clearSearchDescription: PropTypes.string,
    disableTypeahead: PropTypes.bool,
    hideSubmitButton: PropTypes.bool,
    useGlobalSearchConfiguration: PropTypes.bool,
    forceShowClearSearch: PropTypes.bool,
    lockPage: PropTypes.bool,
    isDrawer: PropTypes.bool,
    clearText: PropTypes.string,
    exitText: PropTypes.string,
    hideClearOnEmpty: PropTypes.bool,
    alwaysPad: PropTypes.bool,
    makeSuggestionsScrollable: PropTypes.bool,
};

/**
 * @property defaultProps
 * @type {
 * {query: string, placeholderText: string, btnText: string,
 * resultsRedirectUrl: string, checkIfSelected: null, customSubmit: null, btnClickRequired: boolean,
 * mobileSearchButton: boolean, onChange: null, maxLength: number, inputId: *,
 * minLengthForSearch: number, sortType: boolean, typeCheck: string}}
 */
const defaultProps = {
    query: '',
    placeholderText: 'Search',
    btnText: 'Search',
    resultsRedirectUrl: '',
    checkIfSelected: null,
    customSubmit: null,
    btnClickRequired: false,
    mobileSearchButton: false,
    onChange: null,
    maxLength: 0,
    inputId: uniqueid('input-'),
    minLengthForSearch: 3,
    sortType: false,
    typeCheck: '',
    showClearSearch: {value:false},
    clearSearchTitle: 'Clear Search',
    clearSearchDescription: 'Clear the search form.',
    disableTypeahead: false,
    hideSubmitButton: false,
    useGlobalSearchConfiguration: false,
    forceShowClearSearch: false,
    lockPage: false,
    isDrawer: false,
    alwaysPad: false,
    makeSuggestionsScrollable: false,
};

/**
 * Initialize the TypeAheadSearchAPI
 * @type {TypeAheadSearchAPI}
 * @private
 */
const _TypeAheadSearchAPI = new TypeAheadSearchAPI();

/**
 * Get xpath variables for tagging
 * @param {object} suggestion Incoming Suggestion
 * @returns {object} Attributes to be spread on the suggestion item
 */
function getXpathAttrs(suggestion) {
    const attrs = {};
    let nameString = 'data-xpath-';
    let idString = 'data-xpath-';
    switch (suggestion.type) {
        case 'exhibitor':
            nameString = `${nameString}exhibitorname`;
            idString = `${idString}exhibitorid`;
            break;
        case 'product':
            nameString = `${nameString}productname`;
            idString = `${idString}productid`;
            break;
        case 'article':
            nameString = `${nameString}articlename`;
            idString = `${idString}articleid`;
            break;
        case 'event':
            nameString = `${nameString}eventname`;
            idString = `${idString}eventid`;
            break;
        case 'line':
            nameString = `${nameString}linename`;
            idString = `${idString}lineid`;
            break;
        case 'lineproduct':
            nameString = `${nameString}productname`;
            idString = `${idString}productid`;
            break;
        default:
            break;
    }
    if (nameString !== 'data-xpath-') {
        attrs[nameString] = suggestion.label;
        attrs[idString] = suggestion.id;
    }
    return attrs;
}
/**
 * TypeAheadSearchForm component class
 * Wrapper for the auto search and results
 */
class TypeAheadSearchForm extends Component {


    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        const { query } = props;
        this.runSearch = this.runSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.redirectUrl = this.redirectUrl.bind(this);
        this.getDestinationUrl = this.getDestinationUrl.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.closeOtherMenus = this.closeOtherMenus.bind(this);
        this.renderOptionsBar = this.renderOptionsBar.bind(this);
        this.isOpenedMenu = this.isOpenedMenu.bind(this);
        this.renderAlertBox = this.renderAlertBox.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.showCompactView = this.showCompactView.bind(this);
        this.updateAlertPosition = this.updateAlertPosition.bind(this);
        this.getResults = debounce(this.props.actions.getResults, 250);
        this.inputSearchRef = (this?.props?.searchRef) ? this.props.searchRef : React.createRef();
        this.wrapperRef = React.createRef();
        this.formRef = React.createRef();
        this.placeholderRef = React.createRef();
        this.exitSearchRef = React.createRef();
        this.drawerRef = React.createRef();
        this.adjustContainerHeight = this.adjustContainerHeight.bind(this);
        this.resetResult = this.props.actions.resetResult;
        this.minLengthForSearch = props.minLengthForSearch;
        this.disableTypeahead = props.disableTypeahead;
        this.renderDrawerSuggestions = this.renderDrawerSuggestions.bind(this);
        this.getRecentSearches = this.getRecentSearches.bind(this);
        this.getDrawerStyles = this.getDrawerStyles.bind(this);

        this.state = {
            closeOtherMenusOpenerId: '',
            valueObj: {},
            isValid: (query.length >= this.minLengthForSearch),
            isLoading: false,
            isSuggestionSelected: false,
            padding: 0,
            messageType: undefined,
            message: undefined,
            alertboxMarginTop: 10,
            types: [],
            isFormFocused: false,
            containerHeight: 'auto',
            visualViewportHeight: '100vh',
            recentSearch: [],
        };
    }

    componentDidMount() {
        let padding = window.innerWidth - document.documentElement.getBoundingClientRect().width;
        if (padding > 100) padding = 0;
        if (this.props.showMessage) {
            this.searchPubSubService = SearchPubSubService.getInstance();

            this.searchPubSubService.messageChange().subscribe(messageChange => {
                this.setState({
                    messageType: messageChange.type,
                    message: messageChange.message,
                    padding: padding,
                })
            });
            const searchType = getSearchTypeFromPath(this.props.location.pathname);
            const messageChanged = {
                type: 'info',
                message: getDictionaryValue(`${SEARCH_RESULTS_DICTIONARY_KEY_PREFIX}default_${searchType}`, 'Search & Discover from 4,000+ Lines and 1,000,000+ Products'),
            };
            this.searchPubSubService.emitMessageChanged(messageChanged);
            window.addEventListener('resize', this.updateAlertPosition);
        } else {
            this.setState({
                padding: padding,
            });
        }
        window.addEventListener('scroll', this.handleScroll);
        if (this.wrapperRef.current) {
            this.wrapperRef.current.addEventListener('focusin', this.handleFormFocus);
            this.wrapperRef.current.addEventListener('focusout', this.handleFormBlur);
            this.wrapperRef.current.addEventListener('keydown', this.handleKeyDown);
            this.wrapperRef.current.addEventListener('click', this.handleClick);
        }
        if (typeof window.visualViewport !== "undefined") {
            this.adjustContainerHeight();
            window.visualViewport.addEventListener('resize', this.adjustContainerHeight);
        }
        this.getRecentSearches();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateAlertPosition);
        window.removeEventListener('scroll', this.handleScroll);
        if (this.wrapperRef.current) {
            this.wrapperRef.current.removeEventListener('focusin', this.handleFormFocus);
            this.wrapperRef.current.removeEventListener('focusout', this.handleFormBlur);
            this.wrapperRef.current.removeEventListener('keydown', this.handleKeyDown);
            this.wrapperRef.current.removeEventListener('click', this.handleClick);
        }
        if (typeof window.visualViewport !== "undefined") {
            window.visualViewport.removeEventListener('resize', this.adjustContainerHeight);
        }
    }

    /**
     * Component Update
     */
    componentDidUpdate(prevProps, prevState) {
        const { query, sortType, lockPage, typeaheadsearch, alwaysPad } = this.props;
        const isFormFocused = this.state.isFormFocused;
        const prevQuery = prevProps.query;
        const prevTypeaheadsearch = prevProps.typeaheadsearch;
        const prevIsFormFocused = prevState.isFormFocused;

        const typeaheadsearchoptions = (query) ? (sortType ?
            typeaheadsearch.options.filter(option => option.type !== 'article') : typeaheadsearch.options) : [];

        const prevTypeaheadsearchoptions = (prevQuery && prevTypeaheadsearch) ? (sortType ?
            prevTypeaheadsearch.options.filter(option => option.type !== 'article') : prevTypeaheadsearch.options) : [];

        if (typeaheadsearchoptions !== prevTypeaheadsearchoptions || isFormFocused !== prevIsFormFocused) {
            if (!Environment.isServer && lockPage) {
                setTimeout(() => {
                    const focusedElement = document.activeElement;
                    if (isFormFocused !== prevIsFormFocused && alwaysPad) {
                        if (isFormFocused) {
                            padContent(this.state.padding, undefined, undefined, true);
                        } else {
                            unPadContent(undefined, true);
                        }
                    } else if ((typeaheadsearchoptions?.length > 0) && (isFormFocused)) {
                        this.adjustContainerHeight();
                        padContent(this.state.padding, undefined, undefined, true);
                    } else if (typeaheadsearchoptions?.length === 0 && prevTypeaheadsearchoptions?.length > 0 && (isFormFocused)) {
                        // unPadContent(undefined, true);
                    } else if (typeaheadsearchoptions?.length > 0 && (!isFormFocused) && (prevIsFormFocused)) {
                        unPadContent(undefined, true);
                    }
                }, 0);
            }
        }

        if (prevProps.query !== query) {
            this.setState({
                isValid: (query.length >= this.minLengthForSearch),
                alertboxMarginTop: this.getAlertBoxMarginTop()
            });

        } else if (this.state.alertboxMarginTop !== this.getAlertBoxMarginTop())
            this.setState({
                alertboxMarginTop: this.getAlertBoxMarginTop()
            });
    }

    updateAlertPosition() {
        if (this.state.alertboxMarginTop !== this.getAlertBoxMarginTop())
            this.setState({
                alertboxMarginTop: this.getAlertBoxMarginTop(),
                padding: window.innerWidth - document.documentElement.getBoundingClientRect().width
            });
    }

    handleScroll() {
        if (this?.inputSearchRef?.current && this.state.isFormFocused) {
            this.inputSearchRef.current.input.blur();
            this.setState({ isFormFocused: false });
        }
    }

    handleFormFocus = () => {
        this.setState({ isFormFocused: true });
    };

    handleFormBlur = (event) => {
        if (event?.relatedTarget && this?.drawerRef?.current && this.drawerRef.current == event.relatedTarget) {
            this.inputSearchRef.current.input.focus();
        }
        setTimeout(() => {
          if (!this.props.isDrawer && this.state.isFormFocused === true && !this?.formRef?.current?.contains(document.activeElement)) {
            this.setState({ isFormFocused: false });
            this.onSuggestionsClearRequested(); // Clear suggestions if focus is lost from the entire form
          } else if (event.relatedTarget && (!this?.formRef?.current?.contains(document.activeElement) || !this?.wrapperRef?.current?.contains(document.activeElement)) && this.state.isFormFocused === true) {
            this.setState({ isFormFocused: false });
            this.onSuggestionsClearRequested(); // Clear suggestions if focus is lost from the entire form
          }
        }, 0);
    };

    handleKeyDown(event) {
        if (this?.inputSearchRef?.current) {
            const inputElement = this.inputSearchRef.current.input;
            if (inputElement && inputElement === document.activeElement && inputElement.value.trim() === '' && event.key === 'Escape') {
                this.setState({ isFormFocused: false });
                inputElement.blur();
                if (this.props.onAfterSubmit) this.props.onAfterSubmit();
            }
        }
    }

    handleClick(event) {
        if (this?.placeholderRef?.current && this.placeholderRef.current.contains(event.target)) {
            if (event?.target?.href) {
                this.inputSearchRef.current.input.focus();
                this.inputSearchRef.current.input.blur();
                if (this.props.onAfterSubmit) this.props.onAfterSubmit();
            }
        }
    }

    /**
     * Clear the suggestions from field
     * @param {object} suggestion object for templating the suggestion UI
     * @returns {XML} Rendered component
     */
    static renderSuggestion(suggestion, { query }, isDrawer = false) {

        const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const filterInvalidFirstCharacters = (query) => {
            // Define a regular expression pattern that matches non-alphanumeric characters at the start of words
            const invalidStartPattern = /^[^a-zA-Z0-9]+/;
        
            // Split the query into words
            const words = query.split(/\s+/);
        
            // Filter out invalid first characters in each word recursively
            const filteredWords = words.map(word => {
                
                // Handle backslash at the end of the word
                if (word.endsWith('\\')) {
                    word = word.slice(0, -1);
                }

                while (invalidStartPattern.test(word)) {
                    word = word.replace(invalidStartPattern, '');
                }
                return word;
            }).filter(word => word.trim() !== "");
        
            // Join the filtered words back into a single string
            const filteredQuery = filteredWords.join(' ');
            return filteredQuery;
        }

        const suggestionText = `${suggestion.label}`;
        const suggestionType = `${suggestion.type}`;

        // Filter invalid first characters from the query
        const filteredQuery = filterInvalidFirstCharacters(query);

        const queryWords = filteredQuery.split(/\s+/);
        const escapedWords = queryWords.map(escapeRegex);
        const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');
        const parts = suggestionText.split(regex);
        const xpath = getXpathAttrs(suggestion);
        let extraClass;
        if (suggestionType === 'product') {
            extraClass = 'react-autosuggest__type__product';
        } else if (suggestionType === 'line') {
            extraClass = 'react-autosuggest__type__line';
        } else if (suggestionType === 'exhibitor') {
            extraClass = 'react-autosuggest__type__exhibitor';
        } else if (suggestionType === 'article') {
            extraClass = 'react-autosuggest__type__article';
        } else if (suggestionType === 'info') {
            extraClass = 'react-autosuggest__type__info';
        } else if (suggestionType === 'category') {
            extraClass = 'react-autosuggest__type__category';
        } else {
            extraClass = null;
        }
        return (
            <span {...xpath} className={'react-autosuggest__suggestion-content'}>
                <span className={'react-autosuggest__row'}>
                    {parts.map((part, index) =>
                        (regex.test(part)) ? (
                            <strong key={index}>{part}</strong>
                        ) : (
                            <span key={index}>{part}</span>
                        )
                    )}
                    {(suggestion.isNewToMarket) && <span className={`imc-content--bold imc-type--color-neutral-heavy new-to-market imc-section--padded-xsmall imc-section--padded-left-small imc-section--padded-right-small imc-section--margin-left-medium`}>New</span>}
                    {(!isDrawer) && <span className={`react-autosuggest__type ${extraClass}`}>{suggestionType}</span>}
                </span>
            </span>
        );
    }

    /**
     * Extract the value from object
     * @param {object} suggestion object with result key values
     * @return {string} result string only
     */
    static getSuggestionValue(suggestion) {
        return `${suggestion.label}`;
    }

    getDrawerStyles() {
        let inlineStyles = {};
        const { makeSuggestionsScrollable } = this.props;
        let maxHeight = 0;
        if (makeSuggestionsScrollable) {
            if (this.inputSearchRef.current) {
                const inputElement = this.inputSearchRef.current.input;
                if (inputElement) {
                    const rect = inputElement.getBoundingClientRect();
                    maxHeight = window.innerHeight - (rect.top + rect.height);
                    inlineStyles.maxHeight = `${maxHeight}px`;
                    inlineStyles.overflowY = 'auto';
                }
            }
        }
        return inlineStyles;
    }

    renderDrawerSuggestions(typeaheadsearch, query) {
        const inlineStyles = this.getDrawerStyles();
        const types = [...new Set(typeaheadsearch.options.map(option => option.type))];
        return (
            <div
                className={`imc-searchform--drawer--suggestions-wrapper`}
                style={inlineStyles}
                tabIndex={0}
                ref={this.drawerRef}
            >
                {types.map((type, index) => {
                    const typeOptions = typeaheadsearch.options.filter(option => option.type === type);
                    const typeOptionsLength = typeOptions.length;
                    return (
                        <div key={index} className="imc-searchform--drawer--suggestions">
                            <div className={`imc-content--display-flex imc-section--margin-top-xlarge imc-section--padded-bottom-small imc-content--border-bottom imc-content--border-bottom--medium`}>
                                <h4 className={`imc-heading--zeta imc-content--display-flex-grow-1`}>{type}</h4>
                                <span className={`imc-heading--zeta`}>{typeOptionsLength} Result{(typeOptionsLength > 1) ? 's' : ''}</span>
                            </div>
                            <ul className={`imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-mediumsmall imc-section--margin-top-small`}>
                                {typeOptions.map((suggestion, index) => {
                                    return (
                                        <li key={index}>
                                            <a tabIndex={0} onClick={(event) => {
                                                this.onSuggestionSelected(event, {suggestion});
                                            }} className={`imc-link--hover-underline imc-content--delta imc-content--pointer`}>{TypeAheadSearchForm.renderSuggestion(suggestion, {query}, true)}</a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderRecommendations(props) {
        const inlineStyles = this.getDrawerStyles();
        return (
            <div
                className={`imc-searchform--drawer--suggestions-wrapper`}
                style={inlineStyles}
                tabIndex={0}
                ref={this.drawerRef}
            >
                {(this?.state?.recentSearches?.length > 0) && <div className="imc-searchform--drawer--suggestions">
                    <div className={`imc-content--display-flex imc-section--margin-top-xlarge imc-section--padded-bottom-small imc-content--border-bottom imc-content--border-bottom--medium`}>
                        <h4 className={`imc-heading--zeta imc-content--display-flex-grow-1`}>{(props?.recentSearchesHeader?.value) ? props.recentSearchesHeader.value : 'My Recent Searches'}</h4>
                        <button
                            className={`imc-heading--zeta imc-button--text-only`}
                            onClick={(event) => {
                                if (this?.inputSearchRef?.current) this.inputSearchRef.current.input.focus();
                                this.setState({recentSearches: []});
                                this.setCookie('sessionRecentSearches', '[]');
                            }}
                        >Clear</button>
                    </div>
                    <ul className={`imc-content--display-flex imc-content--display-flex-stretch imc-content--display-flex-wrap imc-content--scroll-x-mobile imc-content--display-flex-gap-xsmall imc-section--margin-top-small`}>
                        {this.state.recentSearches.map((query, index) => {
                            return (
                                <li key={index}>
                                    <button
                                        onClick={(event) => {
                                            props.onChange('', query);
                                            // this.props.actions.getResults(query, this.props.exhibitorId, this.props.types, this.props.useGlobalSearchConfiguration?null:this.props.sitecoreContext.route.itemId);
                                            // if (this?.inputSearchRef?.current) this.inputSearchRef.current.input.focus();
                                            this.runSearch(event, {
                                                label: query
                                            });
                                            this.setState({
                                                isFormFocused: false
                                            });
                                        }}
                                        className={`imc-button imc-content--single-line imc-content--block imc-button--atmarket-gray imc-button--min-width-auto imc-button--transform-none imc-content--full-height imc-section--padded-small imc-section--padded-left-small imc-section--padded-right-small imc-button--radius-3 imc-content--delta`}
                                    >{query}</button>
                                </li>
                            );
                        })}

                    </ul>
                </div>}
                {(isExperienceEditorActive() || props?.placeholders) && <div className="imc-searchform--drawer--suggestions imc-searchform--drawer--placeholder imc-section--margin-top-xlarge " ref={this.placeholderRef}><Placeholder name="imc-jss-content-section" rendering={{placeholders: props.placeholders}} /></div>}
                {(props?.fields?.usefulLinks?.length > 0) && <div className="imc-searchform--drawer--suggestions">
                    <div className={`imc-content--display-flex imc-section--margin-top-xlarge imc-section--padded-bottom-small imc-content--border-bottom imc-content--border-bottom--medium`}>
                        <h4 className={`imc-heading--zeta imc-content--display-flex-grow-1`}>{(props?.usefulLinksHeader?.value) ? props.usefulLinksHeader.value : 'Useful Links'}</h4>
                    </div>
                    <ul className={`top-nav-plan-list-item top-nav-mobile-menu`}>
                        {this.props.fields.usefulLinks.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link onClick={(event) => {
                                        this.setState({ isFormFocused: false });
                                        this.onSuggestionsClearRequested();
                                    }} field={item.fields.link} className={`imc-content--normal imc-button imc-button--atmarket-gray imc-button--no-border imc-button--transparent imc-flex-between-space imc-section--padded-left-none imc-section--padded-right-none imc-section--padded-top-small imc-section--padded-bottom-small imc-content--height-fit-content imc-content--full-width imc-content--no-upper-case`}>
                                        <span>{item.fields.link.value.text}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>}
            </div>
        )
    }

    getRecentSearches = (newSearch = null) => {

        const cookieName = 'sessionRecentSearches';
        const cookieValue = this.getCookie(cookieName);
    
        let recentSearches = cookieValue ? JSON.parse(cookieValue) : [];
    
        if (newSearch) {
            recentSearches = recentSearches.filter((search) => search !== newSearch);
            recentSearches.unshift(newSearch);
            if (recentSearches.length > 15) {
                recentSearches = recentSearches.slice(0, 15);
            }
            this.setCookie(cookieName, JSON.stringify(recentSearches));
        }
    
        this.setState({ recentSearches });
    };

    getCookie = (name) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? decodeURIComponent(match[2]) : null;
    };
  
    setCookie = (name, value) => {
        document.cookie = `${name}=${encodeURIComponent(value)}; path=/;`;
    };

    /**
     * Clear function when 'X' is clicked.
     */
    onClear() {
        this.onChange(null, { newValue: '', method: '' });
        this.setState({
            valueObj: {},
        }, () => {
            this.runSearch(null, null, true);
        });
    }

    /**
     * OnChange event for text field
     * @param {object} event Query to update to the state
     */
    onChange(event, { newValue, method }) {
        const isValid = newValue.length >= this.minLengthForSearch;
        const pageId = this.props.sitecoreContext.route.itemId;
        this.setState({
            isValid,
            isSuggestionSelected: false,
        });

        const { checkIfSelected } = this.props;
        if (checkIfSelected !== null) {
            checkIfSelected(false);
        }

        if (method === 'type' && isValid && !this.disableTypeahead) {
            // dispatches action to fetch
            // suggested results from the api
            // If the typeahead is configured to use global search it won't pass rthe current page Id
            this.getResults(newValue, this.props.exhibitorId, this.props.types, this.props.useGlobalSearchConfiguration?null:pageId);
        }

        // propagate typed input to parent component
        // eslint-disable-next-line no-unused-expressions
        this.props.onChange && this.props.onChange('', newValue);
    }

    /**
     * Updates the suggestion state
     */
    onSuggestionsFetchRequested({ value, reason }) {
        if (reason === 'input-focused') {
            this.getResults(value, this.props.exhibitorId);
        }
    }

    /**
     * Clear the suggestions from field
     */
    onSuggestionsClearRequested() {
        if (!this.state.isFormFocused) {
          this.setState({ suggestions: [] });
        }
    }
    /**
     * Will be called every time suggestion is selected via mouse or keyboard.
     * @param {object} event Query to update to the state
     */
    onSuggestionSelected(event, { suggestion }) {
        this.setState({
            isSuggestionSelected: true,
            valueObj: suggestion,
            suggestions: [],
            isFormFocused: false,
        });
        const { checkIfSelected, btnClickRequired, query, typeCheck } = this.props;
        if (checkIfSelected !== null) {
            checkIfSelected(true);
        }
        if (!btnClickRequired) {
            // eslint-disable-next-line no-unused-expressions
            this.props.customSubmit ? this.props.customSubmit(suggestion.label, suggestion) : this.runSearch(null, suggestion);
        } else {
            this.redirectUrl(suggestion);
            if(this.props.onAfterSubmit) this.props.onAfterSubmit();
            this.getRecentSearches(query);
            
        }
    }
    /**
     * Get Suggestion based on the value
     * @param {string} value text feild updated value
     * @return {object} return suggestion matched value
     */
    getSuggestions(value) {
        
        const { typeaheadsearch } = this.props;
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp(`\\b${escapedValue}`, 'i');
        return typeaheadsearch.options.filter(result => regex.test(TypeAheadSearchForm.getSuggestionValue(result)));
    }
    /**
 * Redirect Url according to data selected in Header
 * @param {object} suggestion Suggestion object
 */
    redirectUrl(suggestion) {
        const { query, btnClickRequired, resultsRedirectUrl,history } = this.props;
        let queryText = query;
        let valObj = Object.assign({}, suggestion);
        if (!btnClickRequired && suggestion) {
            queryText = suggestion.label;
        }
        let _resultsRedirectUrl = resultsRedirectUrl || window.location.pathname;
        const siteMainURL = `${window.location.origin}`;
        let type = !!valObj.type?valObj.type.toLowerCase().toLowerCase():'';
        switch (type) {
            case 'exhibitor':
                history.push(`${getLocalRedirectionUrl('exhibitorDetailPageUrl')}`.replace('$1', valObj.id));
                break;
            case 'catalog':
                history.push( `${getLocalRedirectionUrl('exhibitorDetailWithCatalogUrl')}`
                    .replace('$1', valObj.exhibitorId));
                break;
            case 'product':
                if(!valObj.lineId){
                    _resultsRedirectUrl =`${getLocalRedirectionUrl('productDetailUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.id);
                }
                else{
                    _resultsRedirectUrl = `${getLocalRedirectionUrl('lineProductDetailUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.lineId).replace('$3', valObj.id);
                }
                history.push(_resultsRedirectUrl);
                break;
            case 'line':
                history.push(`${getLocalRedirectionUrl('lineDetailPageUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.id));

                break;
            case 'article':
            case 'event':
                history.push(valObj.destinationUrl);
                break;
            default:
                const queryObj = decode(window.location.search);
                queryObj.q = queryText || suggestion?.label || '';
                if (!queryObj.q) delete queryObj.q;
                queryObj.page = 1;
                const queryString = encode(queryObj, false, false);
                history.push(`${_resultsRedirectUrl}?${queryString}`);
                break;
        }
    }

    /**
     * Get Destination Url
     * @param {object} suggestion Suggestion object
     */
    getDestinationUrl(suggestion) {
        const { query, history } = this.props;
        const siteMainURL = `${window.location.origin}`;
        const resultsRedirectUrl = '/search';
        let valObj = Object.assign({}, suggestion);
        _TypeAheadSearchAPI.getDestinationUrl(valObj)
            .then((response) => {
                switch (valObj.type) {
                    case 'line': {
                        const exhibitID = response.lines[0].exhibitorId;
                        history.push(`${getLocalRedirectionUrl('lineDetailPageUrl')}`.replace('$1', exhibitID)
                            .replace('$2', valObj.id));
                        break;
                    }
                    case 'lineproduct': {
                        const exhibitID = response.products[0].exhibitorId;
                        const lineId = response.products[0].lineId;
                        history.push(
                            `${getLocalRedirectionUrl('lineProductDetailUrl')}`.replace('$1', exhibitID).replace('$2', lineId)
                                .replace('$3', valObj.id));
                        break;
                    }
                    case 'article':
                    case 'event': {
                        const url = response.data[0].destinationUrl;
                        history.push(`${url}`);
                        break;
                    }
                    default:
                        history.push(`${resultsRedirectUrl}?q=${encode(query, false, false)}`);
                        break;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    /**
     * Activate Suggestion based on the value lenght
     * @param {string} value text field updated value
     * @return {boolean} return true/false
     */
    shouldRenderSuggestions(value) {
        return !this.disableTypeahead && value.trim().length >= this.minLengthForSearch;
    }

    getAbsoluteHeight(el) {
        var styles = window.getComputedStyle(el);
        var margin = parseFloat(styles['marginTop']) +
            parseFloat(styles['marginBottom']);

        return Math.ceil(el.offsetHeight + margin);
    }

    closeOtherMenus(openerId) {
        this.setState({
            closeOtherMenusOpenerId: openerId,
        })
    }
    isOpenedMenu() {
        return this.state.closeOtherMenusOpenerId !== '';
    }
    isMobile() {
        return window && Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < DESKTOP_BREAKPOINT;
    }

    showCompactView() {
        const { showSavedSearches, showMultiSelect, showSort, showShare } = this.props;
        let count = 0;
        if (showSavedSearches) count++;
        if (showMultiSelect) count++;
        if (showSort) count++;
        if (showShare) count++;
        return count === 1;
    }
    renderSuggestionsContainer({ containerProps, children }) {
        const { navContainer } = this.props;
        const { ref, ...restContainerProps } = containerProps;
        const callRef = isolatedScroll => {
            if (isolatedScroll !== null) {
                ref(isolatedScroll.component);
            }
        };
        // Set container's Height for scrolling.
        let containerHeight;
        if (typeof document !== "undefined") {
            containerHeight = this.state.containerHeight;
        } else {
            containerHeight = 'auto';
        }

        return (
            <IsolatedScroll
                ref={callRef}
                {...restContainerProps}
                style={{
                    maxHeight: containerHeight
                }}
            >
                {children}
            </IsolatedScroll>
        );
    }

    adjustContainerHeight() {
        const container = document.querySelector('.imc-searchform--form.imc-searchform--mobile');
        if (container) {
            const inputElement = container.querySelector('.imc-searchform--input');
            if (inputElement) {
                const rect = inputElement.getBoundingClientRect();
                const availableHeight = window.innerHeight - (rect.top + rect.height);
                this.setState({
                    containerHeight: `${availableHeight}px`,
                    visualViewportHeight: `${window.visualViewport.height}px`
                });
            }
        }
    }

    /**
     * Runs a search query
     * @param {object} e Event object
     * @param {object} suggestion Suggestion object
     */
    runSearch(e, suggestion, clear=false) {
        if (!clear) window.scrollTo(0, 0);
        let isHeader=false;
        if (e) {
            e.preventDefault();
            isHeader = !!e.target.closest(".header-search-wrap");
        }

        this.setState({
            isFormFocused: false,
        })
        
        const { valueObj } = this.state;
        const { customSubmit, btnClickRequired } = this.props;

        let query = (suggestion?.label) ? suggestion.label : this.props.query;
        
        ImcDataLayer.pushInteractionEvent("search", isHeader?"submit-header":"submit-main", query);
        if (customSubmit !== null) {
            if (btnClickRequired) {
                customSubmit(valueObj);
            } else {
                customSubmit(query);
            }
        } else {
            if (!query && this?.inputSearchRef?.current?.input && !(/^\/search\//i.test(window.location.pathname)) && !(/\/exhibitor\/.*directory/i.test(window.location.pathname))) {
                let parsed = decode(window.location.search);
                if (query) {
                    parsed.q = query;
                } else {
                    delete parsed.q;
                }
                this.props.history.push({search: encode(parsed, false, false)});
                this.inputSearchRef.current.input.focus();
            } else {
                if (suggestion) {
                    this.redirectUrl(suggestion);
                } else if (this?.inputSearchRef?.current?.input && (/\/exhibitor\/.*directory/i.test(window.location.pathname))) {
                    let parsed = decode(window.location.search);
                    if (query) {
                        parsed.q = query;
                    } else {
                        delete parsed.q;
                    }
                    this.props.history.push({search: encode(parsed, false, false)});
                    if (query) {
                        this.inputSearchRef.current.input.blur();
                    } else {
                        this.inputSearchRef.current.input.focus();
                    }
                } else {
                    this.redirectUrl(suggestion);
                    if (query) {
                        this.inputSearchRef.current.input.blur()
                    } else {
                        this.inputSearchRef.current.input.focus();
                    }
                }
            }
        }
        if (query && !clear) this.getRecentSearches(query);
        if(this.props.onAfterSubmit && !clear) this.props.onAfterSubmit();
        
    }


    getAlertBoxMarginTop() {
        const openedMenu = document.getElementsByClassName('imc-searchform--bar--dropdown open');
        return (this.isOpenedMenu() && (!this.showCompactView() || !this.isMobile()) && openedMenu && openedMenu.length === 1)
            ? openedMenu[0].clientHeight + 20 : 8;
    };

    getOptions(sortResultsBy) {
        if (Array.isArray(sortResultsBy)) {
            return sortResultsBy.map(el => { return { value: el.fields.name.value, key: el.fields.key.value, } });
        }
        return [];
    }
    renderAlertBox(compactView, extraClass) {
        const { alertboxMarginTop, message, messageType } = this.state;
        const { showMessage } = this.props;
        return (showMessage && message) ?
            <span style={{ marginTop: alertboxMarginTop }} className={extraClass} >
                <AlertBox text={message}
                    extraClass={`${messageType === 'info' ? 'imc-section--padded-top-none imc-section--padded-bottom-none imc-type--color-neutral-heaviest' : ''} ${compactView ? 'compact-view' : ''}`}
                ></AlertBox>
            </span> : null;
    }


    renderOptionsBar() {
        const { showSavedSearches, showMultiSelect, showSort, showShare, hideSearchType, hideFilterBy, hideSortResultBy } = this.props;
        const { closeOtherMenusOpenerId } = this.state;
        const _showSort = showSort && (!hideFilterBy || !hideSearchType || !hideSortResultBy);
        let query="";
        
        if (typeof window !== 'undefined') {
            const queryObj = decode(window.location.search);
            query = queryObj.q;
        }

        return < >
            {
                showSavedSearches &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <SavedSearchesButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        query={query}
                    ></SavedSearchesButton>
                </div>
            }
            {
                showMultiSelect &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <MultiSelectButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                    ></MultiSelectButton>
                </div>
            }
            {
                _showSort &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <SortButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        sortOptions={this.getOptions(this.props.fields.sortResultsBy)}
                        searchWithinOptions={this.getOptions(this.props.fields.searchType)}
                        hideSearchType={hideSearchType}
                        hideFilterBy={hideFilterBy}
                        hideSortResultBy={hideSortResultBy}
                    ></SortButton>
                </div>
            }
            {
                showShare &&
                <div className={`imc-searchform--bar--button--wrapper share-wrapper`}>
                    <ShareButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        query={query}
                        exhibitorId={this.props.exhibitorId}
                    ></ShareButton>
                </div>
            }
        </>;
    }

    /**∫
     * Renders the JSX view
     * @returns {XML} Rendered component
     */
    render() {
        const { isValid } = this.state;
        const { placeholderText, btnText, maxLength, inputId, mobileSearchButton, typeaheadsearch, hideSubmitButton, query, sortType, showMessage, extraClass, exhibitorId, extraFormClass, extraContainerClass, lockPage, isDrawer, clearText, exitText, hideClearOnEmpty } = this.props;           
        const {showClearSearch} = this.props.fields;       
        const inputProps = {
            placeholder: placeholderText,
            value: query,
            onChange: this.onChange,
            className: `imc-searchform--input imc-type--title-1-ui${hideSubmitButton ? ' imc-searchform--input--hidebutton' : ''}`,
            id: inputId,
            name: 'q',
            exhibitorId: exhibitorId,
        };
        let hasSuggestions = false;
        if (maxLength) {
            inputProps.maxLength = maxLength;
        }
        const typeaheadsearchoptions = (query) ? (sortType ?
            typeaheadsearch.options.filter(option => option.type !== 'article') : typeaheadsearch.options) : [];
        if (!Environment.isServer && lockPage) {
            const focusedElement = document.activeElement;
            if (typeaheadsearchoptions?.length > 0 && (this.state.isFormFocused || this.formRef.current.contains(focusedElement))) {
                hasSuggestions = true;
            } 
        }

        const isDisabled = !query.length;
        const svgClass = !isDisabled ? 'enabled' : '';
        const compactView = this.showCompactView();

        return (
            <>
            <section className={`imc-searchform--section ${extraClass} ${hasSuggestions && lockPage ? 'active' : ''}`} data-xpath="typesearchahead.form" ref={this.wrapperRef}>
                <div className={`imc-searchform--row${(typeaheadsearch?.options?.length > 0 && query) ? ` active` : ``}`}>
                    <form ref={this.formRef} onSubmit={this.runSearch} className={`imc-searchform--form ${(extraFormClass) ? extraFormClass : 0} ${hasSuggestions && lockPage ? 'active' : ''} ${(this.props.exitText) ? `has-exit-text ${(this.state.isFormFocused) ? 'open' : ''}` : ``}`}>
                        <Autosuggest
                            suggestions={typeaheadsearchoptions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={TypeAheadSearchForm.getSuggestionValue}
                            renderSuggestion={TypeAheadSearchForm.renderSuggestion}
                            alwaysRenderSuggestions={this.state.isFormFocused}
                            renderSuggestionsContainer={({ containerProps, children, query }) => 
                                (!isDrawer) ? this.renderSuggestionsContainer({ containerProps, children }) : null
                            }
                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                            onSuggestionSelected={this.onSuggestionSelected}
                            inputProps={inputProps}
                            focusInputOnSuggestionClick={false}
                            id={inputId}
                            ref={this.inputSearchRef}
                            containerProps={{
                                className: "some-css-class",
                            }}
                        />
                        {((!hideClearOnEmpty || query) && (showClearSearch?.value || this.props.forceShowClearSearch)) &&
                            <button
                                type="button"
                                onClick={this.onClear}
                                aria-label={getDictionaryValue('clearSearchTitle', 'Clear Search')}
                                className={`imc-searchform--button--clear
                        ${hideSubmitButton ? 'imc-searchform--button--clear--hidesubmit' : ''}`}
                            >
                                <svg
                                    className={svgClass}
                                    width={(this.props.closeIconSize) ? this.props.closeIconSize : `10`}
                                    height={(this.props.closeIconSize) ? this.props.closeIconSize : `10`}
                                    role="img"
                                    aria-labelledby="clear-search-title clear-search-desc"
                                >
                                    <title id="clear-search-title">{getDictionaryValue('clearSearchTitle', 'Clear Search')}</title>
                                    <desc id="clear-search-desc">{getDictionaryValue('clearSearchDescription', 'Clear the Search Form')}</desc>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${(this.props.closeIcon) ? this.props.closeIcon : 'juniper-close'}`} />
                                </svg>
                                {(clearText) && <span className={`imc-breakpoint-display--hide-mobile`}>{clearText}</span>}
                            </button>
                        }
                        <label htmlFor={inputId} className="imc-hide">
                            {placeholderText}</label>
                        {!hideSubmitButton &&
                            <div className='imc-searchform--button--search'>
                                <button
                                    type="submit"
                                    value={btnText}
                                    disabled={!isValid}
                                    data-xpath="typesearchahead.submitBtn"
                                >
                                    <svg
                                        className={svgClass}
                                        width="32"
                                        height="32"
                                        role="img"
                                        aria-labelledby="btn-search-title btn-search-desc"
                                    >
                                        <title id="btn-search-title">{getDictionaryValue('searchTitle', 'Clear Search')}</title>
                                        <desc id="btn-search-desc">{getDictionaryValue('searchDescription', 'Clear the Search Form')}</desc>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3" />
                                    </svg>
                                </button>
                            </div>
                        }
                        {(this.props.exitText) &&
                            <div className={`imc-searchform--button--exit ${(this.state.isFormFocused) ? `active` : ``}`}>
                                <button
                                    value={this.props.exitText}
                                    className={`imc-button imc-button--text-only`}
                                    ref={this.exitSearchRef}
                                    onClick={(event) => {
                                        if (this.exitSearchRef.current && event.currentTarget === this.exitSearchRef.current) {
                                            event.preventDefault();
                                            this.inputSearchRef.current.input.focus();
                                            this.inputSearchRef.current.input.blur();
                                        }
                                    }}
                                >
                                    <span>{this.props.exitText}</span>
                                </button>
                            </div>
                        }
                        {((this.state.isFormFocused || this.props.alwaysRenderSuggestions) && (isDrawer) && typeaheadsearch?.options?.length > 0 && query) && this.renderDrawerSuggestions(typeaheadsearch, query)}
                        {((this.state.isFormFocused || this.props.alwaysRenderSuggestions) && (isDrawer) && (typeaheadsearch?.options?.length == 0 || query == '')) && this.renderRecommendations(this.props)}
                        {(isExperienceEditorActive()) && this.renderRecommendations(this.props)}
                    </form>
                    {this.renderOptionsBar()}
                    {
                        compactView && this.renderAlertBox(compactView, 'imc-searchform--alert-grow imc-break imc-breakpoint-display--hide-desktop')
                    }
                </div>
                {
                    this.renderAlertBox(false, compactView ? 'imc-breakpoint-display--hide-mobile' : '')
                }
            </section>
            {(!Environment.isServer && lockPage) && 
                <Overlay onClick={(event) => {
                    this.inputSearchRef.current.input.focus();
                    this.inputSearchRef.current.input.blur();
                }} className={`top-nav-overlay imc-modal--overlay search-overlay ${(this.state.isFormFocused) ? 'active' : ''}`} id={`header`} />
            }
            </>
        );
    }
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    return {
        typeaheadsearch: state.typeaheadsearch,
    };
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators^^^^^^^
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
    };
}

TypeAheadSearchForm.propTypes = propTypes;
TypeAheadSearchForm.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(TypeAheadSearchForm)));


// PortalComponent.tsx
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  targetId: string; // ID of the target element where children will be rendered
  children: React.ReactNode; // Children to render inside the portal
}

const PortalComponent: React.FC<PortalProps> = ({ targetId, children }) => {
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    // Find the target element by ID
    const element = document.getElementById(targetId);
    if (element) {
      setTargetElement(element);
    }
  }, [targetId]);

  // If the target element is found, render the children into the portal
  return targetElement ? ReactDOM.createPortal(children, targetElement) : null;
};

export default PortalComponent;

import React from "react";
import {
  isExperienceEditorActive,
  withSitecoreContext,
  Image
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router";
import PropTypes from "prop-types";
import ReactModal from 'react-modal';
import { Loading } from 'modules/loading';
import ExhibitorsAPI from '../../api/exhibitor.js';

// Utils
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import {
  getExhibitorLineProductsURL, getExhibitorProductsURL, getExhibitorURL, getExhibitorLineURL,
  getSiteName, exhibitorIsLine, productCategoriesToArray, getShopzioUrl,
  locationIsLine, getTitleName, getAllExhibitorLinesURL,
} from 'utils/exhibitor';

// reducers
import digitalShowroomInfoReducer from "./reducers/digitalShowroomInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";
//modules
import { createAppState } from "modules/redux-app-state";
import { ReadMore } from "modules/readmore";
import { ContactExhibitor } from 'modules/contactexhibitormodal';
import { getFloorPlanURL, getBooth } from 'utils/floorplan';
import Link from "../Link";
import ImcDataLayer from 'utils/datalayer';
import { isMarketCtaShouldBeShown, showMarketPlan } from '../../utils/general';
import SvgIcon from 'modules/svgicon';

import MarketPlanButton from 'modules/marketplan/components/MarketPlanButton';

const _exhibitorAPI = new ExhibitorsAPI();
const digitalShowroomState = createAppState();
const noImg = process.env.PUBLIC_URL + '/assets/images/175-175.png';
const noDirectoryImg = process.env.PUBLIC_URL + '/assets/images/1366x411.png';
class DigitalShowroomInfo extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
    this.renderLogo = this.renderLogo.bind(this);
    this.toggle3DShowroomModal = this.toggle3DShowroomModal.bind(this);
    this.render3DShowroomModal = this.render3DShowroomModal.bind(this);
    this.toggleOtherMarketsModal = this.toggleOtherMarketsModal.bind(this);
    this.renderOtherMarketsModal = this.renderOtherMarketsModal.bind(this);
    this.renderExpandedComponent = this.renderExpandedComponent.bind(this);
    this.renderCompactComponent = this.renderCompactComponent.bind(this);
    this.renderingCTABar = this.renderingCTABar.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toogleCompactCTABar = this.toogleCompactCTABar.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.getLinesCount = this.getLinesCount.bind(this);
    this.getActionItem = this.getActionItem.bind(this);

    const { sitecoreContext } = this.props;

    if (typeof sitecoreContext !== 'undefined') {
      if (typeof sitecoreContext.exhibitor !== 'undefined')
        this.exhibitId = sitecoreContext.exhibitor.id;
      if (typeof sitecoreContext.lineProduct !== 'undefined') {
        this.lineId = sitecoreContext.lineProduct.lineId;
        this.exhibitId = sitecoreContext.lineProduct.exhibitId;
      }
    }
    
    this.state = {
      showMobileCTABar: false,
      show3DShowroomModal: false,
      showOtherMarketsModal: false,
      exhibitId: this.exhibitId,
      lineId: this.lineId,
      linesCount: 0,
      logoError: false,
    }

  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    this.getLinesCount()
  }

  dataLayerSent = false;
  componentDidUpdate(prevProps) {

    const { sitecoreContext } = this.props;

    if (typeof sitecoreContext !== 'undefined') {
      this.props.dataActions.updateIdsFromSitecoreContext(sitecoreContext);
    }

    if (prevProps.lineId != this.props.lineId || prevProps.exhibitId != this.props.exhibitId) {
      this.fetchData(this.props.exhibitId, this.props.lineId);
      this.getLinesCount(this.props.exhibitId)
    }

    if (this?.props?.exhibitor?.companyDirectoryImage && this.props.exhibitor.companyDirectoryImage !== prevProps?.exhibitor?.companyDirectoryImage) {
      this.setState({ logoError: false });
    }

    if (document.title.includes("{exhibitorName}") || document.title.includes("{lineName}")) {
      this.titlePage = document.title;
    }

    if (typeof document != 'undefined' && this.props.exhibitor != null && this.titlePage != null) {
      if (exhibitorIsLine(this.props.exhibitor)) {
        document.title = this.props.sitecoreContext.route.fields["Page Title"].value.replace("{lineName}", getTitleName(this.props.exhibitor)).replace("{exhibitorName}", this.props.exhibitor.companyName);
      } else {
        document.title = this.props.sitecoreContext.route.fields["Page Title"].value.replace("{exhibitorName}", getTitleName(this.props.exhibitor));
      }
    }

    if (!!this.props.exhibitor && !!this.props.exhibitor.companyName
      && !!this.props.exhibitId && !this.dataLayerSent
    ) {
      this.dataLayerSent = true;
      const cat = this.props.exhibitor.productCategories.map(c => c.name);
      ImcDataLayer.pushExhibitorViewEvent(this.props.exhibitor.companyName, this.props.exhibitId, cat);
    }

  }

  componentWillUnmount() {
    this.props.dataActions.clearState();
  }

  async getLinesCount(exhibitId = null) {
    const data = await _exhibitorAPI.getExhibitorCountArray([(exhibitId != null ? exhibitId : this.state.exhibitId)]);
    const linesCount = data[0]?.lineCount;
    
    if (linesCount && linesCount >= 0) {
      this.setState({ linesCount })
    }
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
  fetchData(exhibitorId, lineId) {
    const pageId = this.props.sitecoreContext.route.itemId;
    if (locationIsLine(this.props.location)) {
      if (lineId) {
        this.props.dataActions.getExhibitorLineDetail(lineId, exhibitorId);
      } else {
        // this.setState({errCodeFetch:404});
      }
    }
    else {
      if (exhibitorId) {
        this.props.dataActions.getExhibitorDetail(exhibitorId, pageId);
      } else {
        //  this.setState({errCodeFetch:404});

      }
    }
  }

  toggle3DShowroomModal() {
    this.setState({
      show3DShowroomModal: !this.state.show3DShowroomModal,
    })

  }
  toggleOtherMarketsModal() {
    this.setState({
      showOtherMarketsModal: !this.state.showOtherMarketsModal,
    })

  }

  goBack() {
    //this is wrong. Back should take to line or to
    //this.props.history.goBack();
    this.props.history.push((locationIsLine(this.props.location)) ? getExhibitorLineURL(this.props.exhibitId, this.props.lineId) : getExhibitorURL(this.props.exhibitId));
  }
  toogleCompactCTABar() {
    this.setState(
      {
        showMobileCTABar: !this.state.showMobileCTABar,
      }
    )
  }

    getActionItem() {
        const { exhibitor, exhibitId, lineId } = this.props;
        return {
            showroomName: exhibitor.companyName,
            exhibitorId: exhibitId,
            lines: (lineId) ? [{
                name: exhibitor.lineName,
                lineGuid: lineId,
            }] : null,
            lastVisited: null,
            photosCount: 0,
        }
    }

  getOtherMarketTitle(lease, exhibitor) {
    if ('channel' in lease)
      return exhibitorIsLine(exhibitor) ?
        <>{`${getDictionaryValue('shownAtChannel', `Shown at ${lease.channel.channelName}`, { channelName: lease.channel.channelName })} ${getDictionaryValue('by', 'By')} `}<span className="imc-type--body-1-link imc-type--color-primary-medium">{exhibitor.companyName}</span> </> :
        <>{getDictionaryValue('exhibitsInChannel', `Exhibits in ${lease.channel.channelName}`, { channelName: lease.channel.channelName })}</>;

  }
  getOtherMarketShowroomURL(lease) {
    if ('channel' in lease) {
      if (lease.channel.directoryUrl) {
        return encodeURI(`${lease.channel.directoryUrl}`.replace("{exhibitorId}", this.props.exhibitId).replaceAll("{exhibitorName}", this.props.exhibitor.companyName).replaceAll("{exhibitorNameSearchFriendly}", this.props.exhibitor.companyName.replaceAll(" ", "+")));
      }
      else
        return `${lease.channel.channelUrl}/exhibitor/${this.props.exhibitId}`;
    }
  }
  isLocalMarket(lease) {
    return (typeof window !== 'undefined' && 'channel' in lease && lease.channel.siteCode === window.channel.name);
  }
  getLocalMarket(exhibitor) {
    return exhibitor.activeLeases && exhibitor.activeLeases.filter(this.isLocalMarket);
  }
  getOtherMarkets(exhibitor) {
    return exhibitor.activeLeases && exhibitor.activeLeases.filter((l) => !this.isLocalMarket(l));
  }

  getShowrooms(localLeases) {
    let filterdShowroom = [];
    let added = [];
    if (localLeases) {
      localLeases.map(function (lease, indexa) {
        return (lease.showrooms.map(function (showroom, index) {

          if (!added.includes(showroom.showroom)) {
            filterdShowroom.push(showroom);
            added.push(showroom.showroom);
          }
        })
        )
      })
    }
    return filterdShowroom;
  }
  /**
   * Render helpers
   */

  renderLogo(compact = false) {
    const { exhibitor } = this.props;
    const { fields } = this.props.rendering;
    return (
      <>{(exhibitor?.companyLogoImage && !this.state.logoError) && <div className={compact ? '' : "imc-exhibitors--flex"}>
        {
          exhibitor.companyLogoImage && (
            <div className={`imc-exhibitors--detail-image-container${compact ? '-compact' : ''}`}>
              <img
                src={exhibitor.companyLogoImage}
                alt={exhibitor.companyName}
                className={`imc-exhibitors--detail-image${compact ? '-compact' : ''}`}
                onError={(e) => {
                  this.setState({ logoError: true });
                }}
                onLoad={(e) => {
                  this.setState({ logoError: false });
                }}
              />
            </div>
          )
        }
        {/* {
          !exhibitor.companyLogoImage && (
            <Image field={fields.alternativeCompanyPicture.value} className={`imc-exhibitors--detail-image${compact ? '-compact' : ''} imc-exhibitors--detail-image-default${compact ? '-compact' : ''}`}></Image>
          )
        } */}
      </div>}</>);
  }
  renderLocations(exhibitor, compact = false) {
    let localLease = this.getLocalMarket(exhibitor);
    let filteredShowrooms = this.getShowrooms(localLease);
    let hasMobileCookie = false;
    if (typeof document !== "undefined") {
      hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
    }
    if (localLease) {
      const hasDirectoryImage = !!exhibitor.companyDirectoryImage;
      let addedShowroom = [];
      return (
        <div className={`${!hasDirectoryImage ? 'imc-content--block' : ''}`}>
          <span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center"></span>
          {
            filteredShowrooms.map(function (location, index) {
              let response;
              if (location.showroomFloor && location.showroomBuilding)
                if (!hasMobileCookie) {
                  response = (
                    <Link
                      rel="noopener noreferrer"
                      href={getFloorPlanURL(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                      className={`imc-link imc-type--title-6 imc-content--inline-block`}
                    >
                      {getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                    </Link>
                  );
                } else {
                  response = (
                    <a
                      rel="noopener noreferrer"
                      href={getFloorPlanURL(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                      className={`imc-link imc-type--title-6 imc-content--inline-block`}
                    >
                      {getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                    </a>
                  );
                }
              else
                response = (
                  <span className={`imc-content--inline-block imc-type--title-6`}>
                    {getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                  </span>
                );
              return (
                <span key={index} className="imc-content--pipe">
                  {response}
                </span>
              );
            })
          }
        </div>
      );
    }
    else {
      return <></>;
    }
  }
  render3DShowroomModal() {
    const { exhibitor } = this.props;
    const { show3DShowroomModal } = this.state;
    return (
      <ReactModal
        isOpen={show3DShowroomModal}
        overlayClassName="imc-modal--overlay"
        className={`imc-modal--box`}
        ariaHideApp={false}
      >
        <div className="imc-modal--content imc-modal--position">
          <div className={'imc-section imc-section--padded imc-vr--xlarge imc-modal--content imc-modal--padding-large imc-content--full-width'}
          >
            <div className="modal-3droom-container">
              <div className="imc-content--display-flex imc-content--display-flex--center">
                <span
                  className="imc-heading--alpha imc-heading--giga-desktop imc-heading
                                            imc-vr--small"
                  data-xpath="optinmodal.heading"
                >
                  {`${getTitleName(exhibitor)} ${getDictionaryValue('3dShowroom', '3D Showroom')}`}

                </span>
                <div className="imc-content--inline-block imc-content--display-flex--right">
                  <ContactExhibitor
                    exhibitorId={this.props.exhibitId}
                    extraClass="imc-button--right"
                  />
                </div>
              </div>
              <div class="imc-iframe--container">
                <iframe
                  title="imc-iframe-3dshowroom"
                  // width={modalWidth}
                  // height={modalHeight}
                  src={exhibitor.showroom3DUrl}
                  gesture="media" allow="encrypted-media" allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
        <button className="imc-modal--close imc-button--modal-close imc-button--round" onClick={this.toggle3DShowroomModal}>
          {getDictionaryValue('close', 'Close')}</button>
        {/* {this.renderIframe()} IE FIX? */}
      </ReactModal>
    )
  }

  renderOtherMarketsModal(otherMarkets, count) {
    const { exhibitor } = this.props;
    const { showOtherMarketsModal } = this.state;
    let added = [];
    return (
      <ReactModal
        isOpen={showOtherMarketsModal}
        overlayClassName="imc-modal--overlay"
        className={'imc-modal--box imc-modal--box-wide'}
        ariaHideApp={false}
      >
        <div className="imc-modal--content imc-modal--position">
          <h2 className="imc-heading--box-modal">
            {`${exhibitor.companyName} ${getDictionaryValue('availableInXMarkets', `Available in ${count} Markets`, { count: count })}`}
          </h2>
          <div className="imc-other-markets-body">
            {
              otherMarkets.map((lease, index) => {
                if (!added.includes(lease.channel.siteCode)) {
                  added.push(lease.channel.siteCode);
                  return (
                    <div className="imc-other-markets-row" key={index}>
                      <div className="imc-other-markets-logo">
                        <img src={`${process.env.PUBLIC_URL}${('channel' in lease) ? lease.channel.logo : ''}`}></img>
                      </div>
                      <div className="imc-other-markets-title">
                        {this.getOtherMarketTitle(lease, exhibitor)}
                      </div>
                      <div className="imc-other-markets-goto">
                        <Link href={this.getOtherMarketShowroomURL(lease)} target="_blank" rel="noopener noreferrer">
                          {lease.channel.channelCode === "imchp" ?
                            getDictionaryValue('goToHighpointShowroom', 'Visit IMC High Point Market Website') : getDictionaryValue('goToShowroom', 'Go to Showroom')}
                        </Link>
                      </div>
                      <div className="imc-other-markets-contact">
                        <ContactExhibitor
                          extraClass={'imc-button--only-text imc-button--hide-icon imc-button--rectangle-small imc-button--min-width-auto  imc-padding--left--none imc-padding--right--none imc-content--right'}
                          exhibitorId={this.props.exhibitId}
                          onPreClick={this.toggleOtherMarketsModal}
                        />
                      </div>
                    </div>
                  );
                }
                else return null;
              })
            }
            {exhibitor.shopZioURL && isMarketCtaShouldBeShown(this.props) &&
              <div className="imc-other-markets-row">
                <div className="imc-other-markets-logo">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/logos/andmore_BLK.png`}></img>
                </div>
                <div className="imc-other-markets-title">
                  {getDictionaryValue('availableOnShopZio', 'Available on @Market')}
                </div>
                <div className="imc-other-markets-goto">
                  <Link
                    onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Go to @Market', exhibitor.shopZioURL)}
                    href={getShopzioUrl(exhibitor.shopZioURL)} target="_blank" rel="noopener noreferrer">
                    {getDictionaryValue('goToShopZio', 'Go to @Market')}
                  </Link>
                </div>
                <div className="imc-other-markets-contact">
                  <ContactExhibitor
                    extraClass={'imc-button--only-text imc-button--hide-icon imc-button--rectangle-small imc-button--min-width-auto imc-padding--left--none imc-padding--right--none imc-content--right '}
                    exhibitorId={this.props.exhibitId}
                    onPreClick={this.toggleOtherMarketsModal}
                  />
                </div>
              </div>
            }

          </div>

        </div>
        <button className="imc-modal--close imc-button--modal-close imc-button--round" onClick={this.toggleOtherMarketsModal}>
          {getDictionaryValue('close', 'Close')}</button>
        {/* {this.renderIframe()} IE FIX? */}
      </ReactModal>
    )
  }

  renderInfo() {
    const { exhibitor } = this.props;
    const hasDirectoryImage = !!exhibitor.companyDirectoryImage;
    return (
      <>
        <div className={`imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-small ${(hasDirectoryImage) ? `imc-section--margin-top-medium` : ``}`}>
          <h1 className={`imc-heading--h4`}>
            {getTitleName(exhibitor)}
          </h1>
          {exhibitorIsLine(exhibitor) && (
          <div className={`imc-content imc-type--title-6 imc-type--color-neutral-heavy-medium imc-vr--small ${!hasDirectoryImage ? 'imc-content--inline-block imc-margin--left--small' : ''}`}>
            {getDictionaryValue('shownBy', 'Shown By')}&nbsp;
            <Link href={getExhibitorURL(this.props.exhibitId)}
              className="imc-link imc-type--title-6-link imc-type--color-neutral-heaviest"
            >
              {exhibitor.companyName}
            </Link>
          </div>
          )}
          {this.renderLocations(exhibitor)}
        </div>
        {(exhibitor.isNewToMarket == true) && (
          <div className={`market-plan-button-wrapper new-to-market imc-section--padded-xsmall imc-section--padded-left-medium imc-section--padded-right-medium`}>
            <span className={`imc-content--bold imc-type--color-neutral-heavy`}>New</span>
          </div>
        )}
      </>);
  }

  renderingCTABar() {
    const { exhibitor, location } = this.props;
    const siteName = getSiteName(exhibitor.companySite);
    const otherMarkets = this.getOtherMarkets(exhibitor);
    const distinctMarkets = !!otherMarkets && otherMarkets.map(item => item.channel.siteCode).filter((value, index, self) => self.indexOf(value) === index);
    const buttonWrapperClasses = "imc-exhibitors--main-cta-button imc-content--center";
    const standardButtonClasses = "imc-button imc-button--primary-inverted imc-button--full-bleed-mobile imc-button--small imc-content--inline-block";
    return (
      <div className="imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1 imc-section--neutral-light">
        <div className="imc-section imc-section--width-100-percent">
          <div className=" imc-content--display-flex imc-content--display-flex-wrap imc-content--display-flex-gap-large imc-padding--top--large imc-padding--bottom--large ">
            <div className={`${buttonWrapperClasses}`}>
              <ContactExhibitor
                extraClass={`${standardButtonClasses}`}
                exhibitorId={this.props.exhibitId}
              />
            </div>
            {exhibitor.shopZioURL && isMarketCtaShouldBeShown(this.props) &&
              <div className={`${buttonWrapperClasses}`}>
                <Link
                  onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', exhibitor.shopZioURL)}
                  href={getShopzioUrl(exhibitor.shopZioURL)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${standardButtonClasses} imc-button--atmarket`}
                >{renderDesktopMobileVariations('shopZio', 'Connect on @Market')}</Link>
              </div>
            }
            {(this.state.linesCount > 0 && !exhibitor?.lineName) &&
              <div className={`${buttonWrapperClasses}`}>
                <Link href={getAllExhibitorLinesURL(this.props.exhibitId)}
                  className={`${standardButtonClasses}`}
                >
                  {`See All ${this.state.linesCount} Lines`}
                </Link>
              </div>
            }
            {(exhibitor.productsCount > 0) &&
              <div className={`${buttonWrapperClasses}`}>
                <Link href={locationIsLine(this.props.location) ? getExhibitorLineProductsURL(this.props.exhibitId, this.props.lineId) : getExhibitorProductsURL(this.props.exhibitId)}
                  className={`${standardButtonClasses}`}
                >{renderDesktopMobileVariations('seeAllProducts', `See All ${exhibitor.productsCount} Products`, { count: exhibitor.productsCount })}</Link>
              </div>
            }
            {exhibitor.showroom3DUrl &&
              <div className={`${buttonWrapperClasses}`}>
                <button
                  onClick={this.toggle3DShowroomModal}
                  className={`${standardButtonClasses}`}
                >{renderDesktopMobileVariations('view3DShowroom', 'View 3D Showroom')}</button>
                {this.render3DShowroomModal()}
              </div>
            }
            {exhibitor.companySite &&
              <div className={`${buttonWrapperClasses}`}>
                <Link
                  onClick={() => ImcDataLayer.pushInteractionEvent("view exhibitor site", exhibitor.companyName, exhibitor.companySite)}
                  href={exhibitor.companySite} target="_blank" rel="noopener noreferrer"
                  className={`${standardButtonClasses}`}
                >{renderDesktopMobileVariations('visitSite', `Visit ${siteName}`, { site: siteName })}</Link>
              </div>
            }
            {(distinctMarkets && distinctMarkets.length > 0) &&
              <div className={`${buttonWrapperClasses}`}>
                <button
                  onClick={this.toggleOtherMarketsModal}
                  className={`${standardButtonClasses}`}
                >{renderDesktopMobileVariations('seeInOtherMarkets', `See in ${distinctMarkets.length} Other Markets`, { count: distinctMarkets.length })}</button>
                {this.renderOtherMarketsModal(otherMarkets, distinctMarkets.length)}
              </div>
            }
          </div>
        </div >
      </div>);
  }

  renderExpandedComponent() {

    const { exhibitor } = this.props;
    const actionItem = this.getActionItem();

    return (
      <><Loading showLoading={this.props.showLoading} />
        {
          (true) &&
          <div className={`imc-exhibitors--info ${(exhibitor.companyDirectoryImage) ? '' : 'no-bg'} imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1 imc-section--padded-bottom-none`}>
            <div className="imc-section imc-section--padded-left-none imc-section--padded-right-none imc-content--center imc-section--relative imc-section--width-100-percent imc-exhibitors--directory-image-container">
              {exhibitor.companyDirectoryImage && <img
                alt={exhibitor.companyName}
                src={exhibitor.companyDirectoryImage}
                onError={(e) => { e.target.src = noDirectoryImg }}
                className="imc-image--responsive align-image-content imc-content--full-width imc-exhibitors--directory-image"
              />}
              {!exhibitor.companyDirectoryImage && <div
                onError={(e) => { e.target.src = noDirectoryImg }}
                className="imc-image--responsive align-image-content imc-content--full-width imc-exhibitors--directory-image imc-section--neutral-medium-light"
              ></div>}
              {showMarketPlan(this.props) && <MarketPlanButton
                  item={actionItem}
                  lineGuid={this.props.lineId}
                  extraClassName={` `}
              />}
              <div className={`imc-exhibitors--info-block ${(exhibitor.companyDirectoryImage) ? 'imc-section--padded-top-desktop-xlarge imc-inset-content-mobile imc-inset-content-jumbo-mobile' : 'imc-section--padded-top-xlarge'} imc-section--padded-bottom-xlarge imc-section `}>
                <div className="imc-exhibitors--info-block-content imc-section--basic-white imc-section--opacity-95">
                  <div className="imc-section imc-section--padded-medium">
                    {this.renderLogo()}
                    {this.renderInfo()}
                  </div>
                  {(this.props.exhibitor.specialsCount > 0) &&
                    <button
                    onClick={() => {
                      document.getElementById('special-details').scrollIntoView({ behavior: 'smooth' });
                    }}
                      className={`imc-content--full-width imc-section imc-section--neutral-heavy imc-section--padded-small imc-content--display-flex imc-content--display-flex-justify-center`}
                    >
                      <SvgIcon width={18} height={18} xlinkHref={'#juniper-starz-2'} className={`svg-blue `} useClassName={`svg-blue svg-white-hover`} />
                      <span className='imc-type--color-basic-white imc-section--margin-left-medium imc-content--bold'>Show Special{(this.props.exhibitor.specialsCount > 1) ? 's' : ''}</span>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        {/* Main CTA BAR */}
        {this.renderingCTABar(exhibitor)}
        {/* Main CTA BAR END*/}
        <div className="imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
          <div className="imc-section imc-section--basic-white imc-exhibitors-info">
            <div className="imc-gallery imc-gallery--66-33">
              <div className="imc-gallery__item imc-vr--xxlarge imc-vr--novr-desktop">
                <h5 className="imc-margin--bottom--medium">
                  {`${getDictionaryValue('aboutName',
                    `About ${exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName}`,
                    { name: exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName })}`}
                  {exhibitorIsLine(exhibitor) &&
                    <> {getDictionaryValue('shownBy', 'Shown By')}&nbsp;
                      <Link href={getExhibitorURL(this.props.exhibitId)}
                        className="imc-link imc-type--title-3"
                      >
                        {exhibitor.companyName}
                      </Link>
                    </>
                  }
                </h5>
                {exhibitor.copyBlock &&
                  <ReadMore
                    content={exhibitor.copyBlock}
                  ></ReadMore>
                }
                {!exhibitor.copyBlock &&
                  <span className="imc-content--neutral-medium">
                    {getDictionaryValue('descriptionNotAvailable', 'Description not available')}
                  </span>

                }
              </div>
              <div className="imc-gallery__item">
                <h5 className="imc-margin--bottom--medium">{getDictionaryValue('productCategoriesShown', 'Product Categories Shown')}</h5>
                {Array.isArray(exhibitor.productCategories) && exhibitor.productCategories.length > 0 &&
                  <div className="imc-gallery imc-gallery--justify-left imc-gallery--1-2">
                    {exhibitor.productCategories.slice(0, 15).map(function (category, index) {
                      return <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right imc-type--title-8 imc-padding--bottom--xsmall" key={index}>{category.name}</div>;
                    })}
                  </div>
                }
                {(Array.isArray(exhibitor.productCategories) && exhibitor.productCategories.length === 0) &&
                  <span className="imc-content--neutral-medium">
                    {getDictionaryValue('productCategoriesNotAvailable', 'Product Categories not available')}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderCompactComponent() {
    const { exhibitor } = this.props;
    const actionItem = this.getActionItem();
    const { showMobileCTABar } = this.state;
    let toggleArrowClass = '';
    let toogleBarClass = 'imc-breakpoint-display--hide-mobile';
    if (showMobileCTABar) {
      toggleArrowClass = 'imc-button--icon-rotate';
      toogleBarClass = ''
    }
    return (<>
      <Loading showLoading={this.props.showLoading} />
      <div className="imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1  imc-section--neutral-light imc-content--border-bottom imc-content--border-top imc-content--border-color-neutral-medium">
        <section className="imc-section imc-section--width-100-percent imc-padding--left--xlarge--desktop imc-padding--right--xlarge--desktop imc-section--padded-none imc-content--display-flex imc-content--display-flex-stretch  imc-content--display-flex-wrap">
          <div className="imc-content--display-flex imc-content--display-flex-stretch imc-content--full-width-mobile imc-content--border-color-neutral-medium">
            <span className="imc-content--border-left imc-content--border-right imc-content--border-color-neutral-medium imc-content--display-flex imc-content--display-flex-center">
              <button className="imc-icon-BackArrow imc-button--icon-only imc-button--no-border imc-heading--mega imc-heading--line-height-1 imc-content--display-flex" title={getDictionaryValue('back', 'Back')} onClick={this.goBack}></button>
            </span>
            <div className="imc-breakpoint-display--hide-mobile imc-content--inline-block  imc-content--border-right  imc-content--border-color-neutral-medium  imc-content--display-flex imc-content--display-flex-center">
              {this.renderLogo(true)}
            </div>
            <div className="imc-content--display-flex imc-content--display-flex-center  imc-content--display-flex-wrap imc-margin--left--smallmedium--mobile imc-margin--left--largemedium--desktop">
              <span className="imc-heading--iota-giga imc-content--display-flex-break-mobile">{getTitleName(exhibitor)}</span>
              {exhibitorIsLine(exhibitor) &&
                <span className="imc-content--zeta imc-padding--left--mediumsmall--desktop imc-exhibitors--shownby-margin">
                  <span className="imc-heading--alt-darkred">{getDictionaryValue('shownBy', 'Shown By')}</span>&nbsp;
                  <Link href={getExhibitorURL(this.props.exhibitId)} className="imc-link">
                    {exhibitor.companyName}
                  </Link>
                </span>
              }
            </div>
            <span className="imc-content--display-flex--right imc-breakpoint-display--hide-desktop imc-content--border-left imc-content--border-right imc-content--border-color-neutral-medium imc-content--display-flex imc-content--display-flex-center">
              <button className={`imc-icon-ExpandArrow imc-button--icon-only imc-button--no-border imc-heading--mega imc-heading--line-height-1 imc-content--display-flex ${toggleArrowClass}`} onClick={this.toogleCompactCTABar}></button>
            </span>
          </div>
          <div className="imc-content--display-flex-break-mobile"></div>
          <div className={`imc-content--display-flex imc-content--display-flex-stretch imc-content--display-flex--right imc-content--border-mobile-bottom imc-content--border-color-neutral-medium imc-content--full-width-mobile ${toogleBarClass}`}>
            <div className="imc-content--display-flex imc-content--display-flex-stretch imc-content--full-width-mobile">
              <span className="imc-content--border-mobile-left imc-content--border-color-neutral-medium imc-margin--right--large--desktop imc-margin--right--small--mobile imc-content--display-flex imc-content--display-flex-center imc-padding--left--mediumsmall--mobile">
                {this.renderLocations(exhibitor, true)}
              </span>
              <div className="imc-content--display-flex imc-content--display-flex-stretch imc-content--display-flex--right">
                <span className="imc-content--border-left imc-content--border-right imc-content--border-color-neutral-medium imc-content--display-flex imc-content--display-flex-center">
                  <ContactExhibitor
                    iconOnly={true}
                    exhibitorId={this.props.exhibitId}
                    extraClass="imc-button--no-border"
                  ></ContactExhibitor>
                </span>

                {exhibitor.shopZioURL && isMarketCtaShouldBeShown(this.props) &&
                  <span className="imc-content--display-flex imc-content--display-flex-center imc-content--border-right imc-content--border-color-neutral-medium">
                    <Link
                      onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'ShopZio', exhibitor.shopZioURL)}
                      href={getShopzioUrl(exhibitor.shopZioURL)} target='_blank' rel="noopener noreferrer" className="imc-button--atmarket--icon-only imc-button--no-border">&nbsp;</Link>
                  </span>
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
    )
  }

  render() {
    const { fields } = this.props.rendering;
    if (!fields) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    //const productCategories = this.getProductCategories(exhibitor.productCategories);
    if (this.props.errCode === 404 || this.state.errCodeFetch === 404) {
      return <Redirect to='/exhibitor-not-exist' />
    }


    return (!!fields.showCompact && fields.showCompact.value) ? this.renderCompactComponent() : this.renderExpandedComponent();

  }
}

const defaultProps = {
  dataActions: {
    getExhibitorDetail: () => { },
  },
  exhibitor: {
    companyDirectoryImage: null,
    companyLogoImage: null,
    companyName: "",
    companySite: "",
    copyBlock: "",
    lineName: "",
    activeLeases: [],
    tags: []
  }

};
const propTypes = {
  dataActions: PropTypes.object
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  if (state.digitalShowroomInfoReducer)
    return {
      exhibitor: {
        companyDirectoryImage: state.digitalShowroomInfoReducer.companyDirectoryImage,
        companyLogoImage: state.digitalShowroomInfoReducer.companyLogoImage,
        companyName: state.digitalShowroomInfoReducer.companyName,
        companySite: state.digitalShowroomInfoReducer.companySite,
        copyBlock: state.digitalShowroomInfoReducer.copyBlock,
        lineName: state.digitalShowroomInfoReducer.lineName,
        activeLeases: state.digitalShowroomInfoReducer.activeLeases,
        productCategories: productCategoriesToArray(state.digitalShowroomInfoReducer.productCategories),
        shopZioURL: state.digitalShowroomInfoReducer.shopZioURL,
        productsCount: state.digitalShowroomInfoReducer.productsCount,
        showroom3DUrl: state.digitalShowroomInfoReducer.showroom3DUrl,
        specialsCount: state.digitalShowroomInfoReducer.specialsCount,
        isNewToMarket: state.digitalShowroomInfoReducer.isNewToMarket,
      },
      errCode: state.digitalShowroomInfoReducer.errCode,
      showLoading: state.digitalShowroomInfoReducer.showLoading,
      exhibitId: state.digitalShowroomInfoReducer.exhibitId,
      lineId: state.digitalShowroomInfoReducer.lineId

    };
  else {
    return {
      exhibitor: {
        companyDirectoryImage: state.companyDirectoryImage,
        companyLogoImage: state.companyLogoImage,
        companyName: state.companyName,
        companySite: state.companySite,
        copyBlock: state.copyBlock,
        lineName: state.lineName,
        activeLeases: state.activeLeases,
        productCategories: state.productCategories,
        shopZioURL: state.shopZioURL,
        productsCount: state.productsCount,
        showroom3DUrl: state.showroom3DUrl,
        isNewToMarket: state.isNewToMarket,
      },
      errCode: state.errCode,
      showLoading: state.showLoading,
      exhibitId: state.exhibitId,
      lineId: state.lineId

    };
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomInfo.propTypes = propTypes;
DigitalShowroomInfo.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomInfo))
);
